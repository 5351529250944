import { addJsError, addAjaxError } from './owl';
import { getCtxId } from './utils';
import FlowControlInterrupt from '@src/types/FlowControlInterrupt';
import { debug } from '@utils/debug';
/**
 * @description: 监听错误信息并上报
 * @return {*}
 */
function initErrorListener() {
  window.addEventListener('error', (event: ErrorEvent) => {
    try {
      if (event?.error instanceof FlowControlInterrupt) {
        event?.preventDefault?.(); // 阻止默认错误处理
        debug('FlowControlInterrupt error: ', event?.error?.message);
        return;
      }

      const { message, lineno, colno, error } = event || {};
      // 这里我们可以访问 error 对象 (如果存在) 的 name 属性
      const errorName = error ? error.name : 'Unknown Error';
      debug('监听到全局js错误');
      addJsError(message, {
        tags: {
          ctxId: getCtxId() as string,
          name: errorName,
          message,
          lineno,
          colno,
          stack: error ? error.stack : 'No stack trace available',
        },
      });
    } catch (err) {
      console.error(err);
    }
  });
  window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
    try {
      if (event?.reason instanceof FlowControlInterrupt) {
        event?.preventDefault?.(); // 阻止默认错误处理
        debug('FlowControlInterrupt unhandledrejection: ', event?.reason?.message);
        return;
      }

      const reason = event?.reason || {}; // 这是拒绝的值（通常是 Error 对象）
      // 如果拒绝的值是一个 Error 对象，可以访问其 message 和 stack
      const errorMessage = reason instanceof Error ? reason.message : String(reason);
      const errorStack = reason instanceof Error ? reason.stack : 'No stack trace available';
      debug('监听到全局ajax错误');
      addAjaxError(errorMessage, {
        tags: {
          ctxId: getCtxId() as string,
          message: errorMessage,
          stack: errorStack || 'unhandledrejection 异常',
        },
      });
    } catch (err) {
      console.error(err);
    }
  });
}
initErrorListener();
