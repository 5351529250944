import { getUrlParams } from './url';
import { ELanguage } from '@src/types/language';

/**
 * @description: 根据语言设置文本方向
 * @return {*}
 */
export function setDir() {
  // 获取语音参数
  const locale = getUrlParams('locale');
  let isRtl = false;
  // 定义一个从语言代码到文本方向的映射
  if (locale === ELanguage.ar) {
    isRtl = true;
  }
  // 获取文档的 <html> 元素
  const htmlElement = document.documentElement;
  htmlElement.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
}
