/**
 *  本地缓存方法,主要做了try catch
 */

function isFunction(obj: any) {
  return Object.prototype.toString.call(obj) === '[object Function]';
}

// Empty Function
function EmptyFunc() {}

// Null FUnction
function NullFunc() {
  return null;
}

interface IStorage {
  length?: number;
  key?: (index: number) => string | null;
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
  clear: (key?: string) => void;
}

// 空的 Storage，模拟相关接口
const EmptyStorage: IStorage = {
  getItem: NullFunc,
  setItem: EmptyFunc,
  removeItem: EmptyFunc,
  clear: EmptyFunc,
};

interface IWindow {
  localStorage: IStorage;
  sessionStorage: IStorage;
}

const isWindow = (data: unknown, type: string): data is IWindow =>
  typeof data === 'object' && data !== null && type in data;

// 先断言（编译器会根据断言识别类型），再取值
function getValue(data: unknown, type: string): IStorage {
  try {
    // @ts-ignore
    return isWindow(data, type) ? data[type] : '';
  } catch (e) {
    return EmptyStorage;
  }
}

// 根据 type 创建 Storage 对象
function createStorage(type: string) {
  // const storage: IStorage = type in window && window[type];
  const storage: IStorage = getValue(window, type);
  if (!storage) {
    return EmptyStorage;
  }

  return {
    // 通过 index 获取键值 key
    key: isFunction(storage.key) ? storage.key : NullFunc,
    // 通过键值 key 获取数据
    getItem: storage.getItem
      ? function getItem(key: string) {
          try {
            return storage.getItem(key);
          } catch (e) {
            return null;
          }
        }
      : NullFunc,
    // 设置键值 key 对应的数据值为 value
    setItem: storage.setItem
      ? function setItem(key: string, value: string) {
          try {
            return storage.setItem(key, value);
          } catch (e) {
            return undefined;
          }
        }
      : EmptyFunc,
    // 移除键值 key 对应的数据项
    removeItem: storage.removeItem
      ? function removeItem(key: string) {
          try {
            return storage.removeItem(key);
          } catch (e) {
            return undefined;
          }
        }
      : EmptyFunc,
    // 清除数据
    clear: storage.clear
      ? function clear(key?: string) {
          try {
            return storage.clear(key);
          } catch (e) {
            return undefined;
          }
        }
      : EmptyFunc,
  };
}

/**
 * @description: localStorage 加try catch
 * @return {IStorage} localStorage
 */
export const ssoLocalStorage = createStorage('localStorage');
/**
 * @description: sessionStorage 加try catch
 * @return {IStorage} localStorage
 */
export const ssoSessionStorage = createStorage('sessionStorage');

export default { ssoLocalStorage, ssoSessionStorage };
