import React, { useEffect } from 'react';
import { useTranslation } from '@sailor/i18n-web';
import { resetLanguageAndRender } from '@utils/resetLanguage';
import { getLoginPageConfig } from '@src/api/loginPage';
import { IRequestCommonAction, IRequestBaseRes } from '@src/types/request';
import { ELoginPageInitStatus } from '@src/types/common';
import { useGlobalContext } from '@src/context';
import WaitLoading from '@components/WaitLoading';
import { isLockLanguageFn } from '@utils/i18n';
import { SupportLangType } from '@common/i18n';
import { ERROR_CODES } from '@common/errorCode';
import { SSOX_LOGIN, pathnameInfo } from '@common/const';
import { isNotGoNumDefaultFn } from '@utils/jump';
import { debug } from '@utils/debug';
import getInitSSOGuardPromise from '@utils/ssoGuard';
import { IMoaMisCheckErrorProps } from '@src/types/accessControl';
import ErrorCom from '@components/Error';

interface IProps {
  children: any;
  uniqueKey: string;
}
/**
 * @description: 获取服务端配置组件,需和getServerConfigHOC配套使用,保障获取配置接口和按需加载能同时进行.
 * @param {IProps} props
 * @return {children}
 */
export default function GetServerConfig(props: IProps) {
  const { children, uniqueKey } = props;
  const { updateLoginPageInitStatus, updateResAction, updateLocale } = useGlobalContext();
  // 初始化
  const init = async () => {
    try {
      if ((location.pathname === pathnameInfo.login || location.pathname === SSOX_LOGIN) && isNotGoNumDefaultFn()) {
        // TODO 改为调后端接口
        window.location.reload();
        return;
      }
      updateLoginPageInitStatus(ELoginPageInitStatus.pending);
      debug('开始上报dfpId-getServerConfig');
      // 等待dfpId生成完成
      await getInitSSOGuardPromise();
      debug('开始调getLoginPageConfig-getServerConfig');
      const res: IRequestCommonAction | IRequestBaseRes = await getLoginPageConfig();
      if (!isLockLanguageFn() && res && 'payload' in res && res.payload?.data?.defaultLanguage) {
        resetLanguageAndRender(res.payload.data.defaultLanguage as SupportLangType, updateLocale);
      }
      updateResAction(res);
      updateLoginPageInitStatus(ELoginPageInitStatus.success);
    } catch (error: any) {
      updateResAction(null);
      updateLoginPageInitStatus(ELoginPageInitStatus.fail, {
        errorCode: error?.code || ERROR_CODES.COMMON.CATCH_ERROR,
        errorMsg: error?.message || '',
        traceId: error?.traceId || '',
      });
    }
  };
  useEffect(() => {
    init();
  }, [uniqueKey]);

  return children;
}

type IWrappedComponentProps = IMoaMisCheckErrorProps;

// 高阶组件
export const getServerConfigHOC = (WrappedComponent) => (props: IWrappedComponentProps) => {
  const { t } = useTranslation();
  const { loginPageInitStatus, loginPageErrorInfo } = useGlobalContext();
  const { errorCode, errorMsg, traceId } = loginPageErrorInfo;
  // const { unnecessaryProp, ...restProps } = props; // 解构赋值，排除不必要的属性
  const { ...restProps } = props;
  if (loginPageInitStatus === ELoginPageInitStatus.success) {
    return <WrappedComponent {...restProps} />;
  }
  if (loginPageInitStatus === ELoginPageInitStatus.fail) {
    return (
      <ErrorCom
        errorTitle={`${t('sso_web_exception_occurred', '发生异常，请重试')} (${errorCode})`}
        errorMsg={errorMsg}
        traceId={traceId}
      />
    );
  }
  return <WaitLoading />;
};
