import { IRequestCommonAction, IRequestBaseRes } from '@src/types/request';
import request from '@utils/request';
import { ApiPrefix } from '@common/const';
import { addAjaxError } from '@utils/owl';

let lastPathname = location.pathname;
// 使用一个 promise 记录请求结果
let pageConfigResPromise: Promise<IRequestCommonAction | IRequestBaseRes> | null = null;
export const getLoginPageConfig = (): Promise<IRequestCommonAction | IRequestBaseRes> => {
  // 跨页面不做缓存, 若需要跨页面缓存的数据需要放在context里
  if (lastPathname !== location.pathname) {
    // pathname 变化，清空结果，重新请求
    pageConfigResPromise = null;
    // 重置 lastPathname 为当前 location.pathname
    lastPathname = location.pathname;
  }
  if (pageConfigResPromise) return pageConfigResPromise;
  pageConfigResPromise = new Promise((resolve, reject) => {
    request
      .post(`${ApiPrefix}/auth/profiles/v1/web/loginPage`)
      .then(resolve)
      .catch((err) => {
        addAjaxError({ name: 'loginPage接口异常', msg: err?.message || '' });
        reject(err);
      });
  });
  return pageConfigResPromise;
};
