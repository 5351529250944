import { changeCurrentLanguage, isInLangValueListFn } from '@utils/i18n';
import { getDaXiangLang } from '@utils/initLanguage';
import { changeI18nLang } from '@src/i18nInit';
import { setDir } from '@utils/setRtlByLanguage';
import { getUrlParams } from '@utils/url';
import { SupportLangType } from '@common/i18n';

/**
 * @description: 如果在大象环境中登录切换语言需要告知大象,移动端大象用的是native的登录页不用告知大象
 * @param {*} newLanguage
 * @return {*}
 */
function notifyDaXiang(newLanguage) {
  const daxiangLanguage = getDaXiangLang();
  const { changeLanguage } = window.DXNative?.i18n || {};
  if (daxiangLanguage && changeLanguage) {
    // 'zh' | 'zh-HK' | 'en'; 目前大象枚举和sso枚举是一致的,不排除以后不一致情况
    changeLanguage(newLanguage);
  }
}

export function resetLanguageAndRender(
  newLanguage: SupportLangType,
  reRenderFn: Function,
  isCheckNewLangEqualUrlLocale = true,
) {
  try {
    const { locale } = getUrlParams();
    if (!isInLangValueListFn(newLanguage)) return;
    if (isCheckNewLangEqualUrlLocale && newLanguage === locale) return;
    changeCurrentLanguage(newLanguage);
    // 重新设置RTL
    setDir();
    // 修改i18n的语言
    changeI18nLang(newLanguage);
    notifyDaXiang(newLanguage);
    // 触发页面重新渲染
    reRenderFn?.(newLanguage);
  } catch (error) {
    console.error(error);
  }
}

// /**
//  * @description: 重置语言并触发重新渲染
//  * @return {*}
//  */
// export function useResetLanguage() {
//   const { updateLocale } = useGlobalContext();
//   function resetLanguageAndRender(newLanguage: SupportLangType) {
//     try {
//       const { locale } = getUrlParams();
//       if (!isInLangValueListFn(newLanguage)) return;
//       if (newLanguage === locale) return;
//       changeCurrentLanguage(newLanguage);
//       // 重新设置RTL
//       setDir();
//       // 修改i18n的语言
//       changeI18nLang(newLanguage);
//       // 触发页面重新渲染
//       updateLocale(newLanguage);
//       notifyDaXiang(newLanguage);
//     } catch (error) {
//       console.error(error);
//     }
//     // 触发重新渲染
//   }
//   return { resetLanguageAndRender };
// }
