import React, { HTMLAttributeAnchorTarget } from 'react';
import classnames from 'classnames';
import styles from './FeedBackPure.scss';
import { Icon } from '@ss/mtd-react';
import { useTranslation } from '@sailor/i18n-web';

interface IProps {
  className?: string;
  color?: string;
  bottomText?: string;
  bottomTextUrl?: string;
  target?: HTMLAttributeAnchorTarget; // a链接跳转target，默认为_self
}

// 不含全局配置的反馈组件
const FeedBackPure = (props: IProps) => {
  const { className, color, bottomText, bottomTextUrl, target = '_self' } = props;
  const { t } = useTranslation();
  return (
    <a
      className={classnames(styles['com-feedback'], className)}
      href={bottomTextUrl || 'mailto:6000@meituan.com'}
      style={{ color }}
      target={target}
    >
      <Icon type="customer-o" className={styles['com-feedback-icon']} />
      <span>{bottomText || t('sso_web_xm_question', '问题反馈')}</span>
    </a>
  );
};

export default FeedBackPure;
