import React, { Suspense, ReactNode } from 'react';
import { Routes, Route, BrowserRouter, Outlet } from 'react-router-dom';
import WaitLoading from '@components/WaitLoading';
import { GlobalContextProvider } from '@src/context';
import NotFound from '@src/nodes/notFound';
import PageWrap from '@src/layout/PageWrap';
import GetServerConfig from '@components/GetServerConfig';
import { SSOX_LOGIN, pathnameInfo } from '@common/const';

const Error = React.lazy(() => import('../nodes/error'));
const Login = React.lazy(() => import('../nodes/login'));
const QrCodeConfirm = React.lazy(() => import('../nodes/qrCode/confirm'));
const QrCodeError = React.lazy(() => import('../nodes/qrCode/error'));
const SecondAuth = React.lazy(() => import('../nodes/secondAuth'));
const SecondAuthSms = React.lazy(() => import('../nodes/secondAuth/sms'));
const SecondAuthVoice = React.lazy(() => import('../nodes/secondAuth/voice'));
const SecondAuthEmail = React.lazy(() => import('../nodes/secondAuth/email'));
const SecondAuthDxToken = React.lazy(() => import('../nodes/secondAuth/dxToken'));
const CoVerifyApply = React.lazy(() => import('../nodes/coVerify/apply'));
const CoVerifyAudit = React.lazy(() => import('../nodes/coVerify/audit'));
const FeedbackHelp = React.lazy(() => import('../nodes/feedbackHelp'));
const ApplyResultPage = React.lazy(() => import('../nodes/coVerify/apply/elements/ApplyResultPage'));
const AuditResultPage = React.lazy(() => import('../nodes/coVerify/audit/elements/AuditResultPage'));

export default function Router(): ReactNode {
  return (
    <GlobalContextProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path={SSOX_LOGIN} // 仅验证用，不对用户使用
            element={
              <GetServerConfig uniqueKey={pathnameInfo.login}>
                <PageWrap isHideLogoAndLangInMobileFromProps={false}>
                  <Suspense fallback={<WaitLoading />}>
                    <Login />
                  </Suspense>
                </PageWrap>
              </GetServerConfig>
            }
          />
          <Route
            path={pathnameInfo.login}
            element={
              <GetServerConfig uniqueKey={pathnameInfo.login}>
                <PageWrap isHideLogoAndLangInMobileFromProps={false}>
                  <Suspense fallback={<WaitLoading />}>
                    <Login />
                  </Suspense>
                </PageWrap>
              </GetServerConfig>
            }
          />
          <Route
            path={pathnameInfo['qr-code']}
            element={
              <Suspense fallback={<WaitLoading />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route index path="confirm" element={<QrCodeConfirm />} />
            <Route path="error" element={<QrCodeError />} />
          </Route>
          <Route
            path={pathnameInfo['second-auth']}
            element={
              <GetServerConfig uniqueKey={pathnameInfo['second-auth']}>
                <PageWrap>
                  <Suspense fallback={<WaitLoading />}>
                    <Outlet />
                  </Suspense>
                </PageWrap>
              </GetServerConfig>
            }
          >
            <Route index element={<SecondAuth />} />
            <Route path="sms" element={<SecondAuthSms />} />
            <Route path="voice" element={<SecondAuthVoice />} />
            <Route path="email" element={<SecondAuthEmail />} />
            <Route path="dx-token" element={<SecondAuthDxToken />} />
          </Route>
          <Route
            path={pathnameInfo['co-verify-apply']}
            element={
              <GetServerConfig uniqueKey={pathnameInfo['co-verify-apply']}>
                <Suspense fallback={<WaitLoading />}>
                  <CoVerifyApply />
                </Suspense>
              </GetServerConfig>
            }
          />
          <Route
            path={pathnameInfo['co-verify-apply-result']}
            element={
              <Suspense fallback={<WaitLoading />}>
                <ApplyResultPage />
              </Suspense>
            }
          />
          <Route
            path={pathnameInfo['co-verify-audit']}
            element={
              // <GetServerConfig uniqueKey={pathnameInfo['co-verify-audit']}>
              <Suspense fallback={<WaitLoading />}>
                <CoVerifyAudit />
              </Suspense>
              // </GetServerConfig>
            }
          />
          <Route
            path={pathnameInfo['co-verify-audit-result']}
            element={
              <Suspense fallback={<WaitLoading />}>
                <AuditResultPage />
              </Suspense>
            }
          />
          <Route
            path={pathnameInfo['feedback-help']}
            element={
              <Suspense fallback={<WaitLoading />}>
                <FeedbackHelp />
              </Suspense>
            }
          />
          <Route
            path={pathnameInfo.error}
            element={
              <Suspense fallback={<WaitLoading />}>
                <Error />
              </Suspense>
            }
          />
          <Route
            path={pathnameInfo['access-control']}
            element={
              <GetServerConfig uniqueKey={pathnameInfo['access-control']}>
                <Suspense fallback={<WaitLoading />}>
                  <Error isAccessControl />
                </Suspense>
              </GetServerConfig>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </GlobalContextProvider>
  );
}
