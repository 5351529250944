export const ApiPrefix = '/sson';

/**
 * @description: 页面名称枚举，用于标识页面
 */
export enum PageCode {
  login = 'login', // 登录页
  'qr-code' = 'qr-code', // 大象扫码
  'qr-code-confirm' = 'qr-code-confirm', // 大象扫码确认页
  'qr-code-error' = 'qr-code-error', // 大象扫码错误页
  error = 'error', // 错误页
  'access-control' = 'access-control',
  'select-account' = 'select-account', // 一手机多账号页面
  'second-auth' = 'second-auth', // 二次认证页面
  'second-auth-sms' = 'second-auth-sms', // 短信二次认证页面
  'second-auth-voice' = 'second-auth-voice', // 语音二次认证页面
  'second-auth-email' = 'second-auth-email', // 邮箱二次认证
  'second-auth-dx-token' = 'second-auth-dx-token', // 邮箱二次认证
  'co-verify-apply' = 'co-verify-apply', // 同事辅助认证
  'co-verify-apply-result' = 'co-verify-apply-result', // 同事辅助认证-结果页
  'co-verify-audit' = 'co-verify-audit', // 同事辅助认证
  'co-verify-audit-result' = 'co-verify-audit-result', // 同事辅助认证-结果页
  'feedback-help' = 'feedback-help', // 同事辅助认证
}

/**
 * @description: 页面灵犀埋点cid 类型
 */
type PageCidMap = {
  [key in PageCode]: string; // 使用枚举键作为对象键
};

/**
 * @description: 页面灵犀埋点cid
 */
export const PAGE_CID: PageCidMap = {
  [PageCode.login]: 'c_oa_5lja19jk',
  [PageCode['qr-code']]: 'c_oa_0hpxp10j',
  [PageCode['qr-code-confirm']]: 'c_oa_csnev0dg',
  [PageCode['qr-code-error']]: 'c_oa_ihsbpvp8',
  [PageCode.error]: 'c_oa_575y7b0o',
  [PageCode['access-control']]: 'c_oa_575y7b0o',
  [PageCode['select-account']]: 'c_oa_f1atwxdq',
  [PageCode['second-auth']]: 'c_oa_dqjfyg6l',
  [PageCode['second-auth-sms']]: 'c_oa_dvc14kf5',
  [PageCode['second-auth-voice']]: 'c_oa_drkbuk6r',
  [PageCode['second-auth-dx-token']]: 'c_oa_7xgu20bl',
  // TODO：
  [PageCode['second-auth-email']]: ``,
  [PageCode['co-verify-apply']]: ``,
  [PageCode['co-verify-apply-result']]: ``,
  [PageCode['co-verify-audit']]: ``,
  [PageCode['co-verify-audit-result']]: ``,
  [PageCode['feedback-help']]: ``,
};

const BASE_PATH = '/sson/page';

/**
 * @description: ssox登录页路径,仅供内部测试用,不对外
 * @return {*}
 */
export const SSOX_LOGIN = '/ssox/login';

/**
 * @description: 路由对象
 */
export const pathnameInfo: PageCidMap = {
  [PageCode.login]: `/sson/login`,
  [PageCode['qr-code']]: `${BASE_PATH}/qr-code`,
  [PageCode['qr-code-confirm']]: `${BASE_PATH}/qr-code/confirm`,
  [PageCode['qr-code-error']]: `${BASE_PATH}/qr-code/error`,
  [PageCode.error]: `${BASE_PATH}/error`,
  [PageCode['access-control']]: `${BASE_PATH}/access-control`,
  [PageCode['select-account']]: `${BASE_PATH}/select-account`,
  [PageCode['second-auth']]: `${BASE_PATH}/second-auth`,
  [PageCode['second-auth-sms']]: `${BASE_PATH}/second-auth/sms`,
  [PageCode['second-auth-voice']]: `${BASE_PATH}/second-auth/voice`,
  [PageCode['second-auth-email']]: `${BASE_PATH}/second-auth/email`,
  [PageCode['second-auth-dx-token']]: `${BASE_PATH}/second-auth/dx-token`,
  [PageCode['co-verify-apply']]: `${BASE_PATH}/co-verify-apply`,
  [PageCode['co-verify-apply-result']]: `${BASE_PATH}/co-verify-apply-result`,
  [PageCode['co-verify-audit']]: `${BASE_PATH}/co-verify-audit`,
  [PageCode['co-verify-audit-result']]: `${BASE_PATH}/co-verify-audit-result`,
  [PageCode['feedback-help']]: `${BASE_PATH}/feedback-help`,
};

// 登录方式枚举
export enum LoginType {
  // SMS登录
  SMS = 'FC_SMS',
  // 个人邮箱登录
  EMAIL = 'FC_EMAIL',
  // 账号密码登录
  FORM = 'FC_PASSWORD',
  // 大象扫码登录
  QR_CODE = 'FC_QRCODE',
  // passkey登录
  PASSKEY = 'FC_PASSKEY',
}

// 执行动作类型
export enum ActionType {
  REDIRECT = 'REDIRECT',
  LOCKED = 'LOCKED',
  FACTORS = 'FACTORS',
  TIP = 'TIP',
  DATA = 'DATA',
  ACCESS_CONTROL = 'ACCESS_CONTROL', // 访问控制
}

// 认证解锁动作类型
export enum UnlockWayType {
  IMAGE = 'CAP_IMAGE', // 图片验证码
  SLIDER = 'CAP_SLIDER', // 滑块验证码
  NONE = 'NONE', // 暂时不可解锁
}

// 多因子认证方式
export enum FactorsType {
  PASSWORD = 'FC_PASSWORD', // 账密认证
  SMS = 'FC_SMS', // 短信验证码
  QR_CODE = 'FC_QRCODE', // 大象扫码
  VOICE = 'FC_VOICE', // 语音验证码
  EMAIL = 'FC_EMAIL', // 个人邮箱
  OTP = 'FC_OTP', // 大象动态口令
  FACE = 'FC_FACE', // 人脸
  DEVICE = 'FC_DEVICE', // 可信设备
  COLLEAGUE = 'FC_ASSISTANCE', // 同事辅助
  FORGET_CELLPHONE = 'FC_FORGET_CELLPHONE', // 忘记手机
  PASSKEY = 'FC_PASSKEY', // Passkey
}

// 访问控制枚举
export enum AccessControlType {
  ENTRANCE = 'AC_ENTRANCE', // 入口权限 (mvp版本实现)
  APPLICATION = 'AC_APPLICATION', // 应用检查 (mvp版本实现)
  TODO = 'AC_TODO', // TODO待办
  MOA_MIS_CHECK = 'AC_MOA_MIS_CHECK', // MOA mis检查
  SINGLE_DEVICE = 'AC_SINGLE_DEVICE', // 单设备检查
}

// 账户类型
export enum EmployeeType {
  PERSON = 1, // 美团正式员工
  INTERN = 2, // 美团实习生
  PARTTIME = 3, // 兼职
  PARTTIME_NEW = 4, // 非PS兼职,
  VIRTUAL = 5, // 虚拟帐号
  SYS = 6, // 系统用户
  DCAGENT = 7, // 到餐代理商
  DZAGENT = 8, // 到综代理商
  LSAGENT = 9, // 零售代理商
  DDAGENT = 10, // 点餐代理商
  WAGENT = 11, // 外卖代理商
  GWAGENT = 12, // 客服代理商
  SYAGENT = 13, // 收银代理商
  BMAGENT = 20, // 配送代理商
  JRAGENT = 22, // 金融代理商
  JWAGENT = 23, // 境外代理商
  Other = -1, // 其他用户
}

export enum CoVerifyInviterType {
  SameCompany = 'SameCompany',
  Colleague = 'Colleague',
  Leader = 'Leader',
  AccountOwner = 'AccountOwner',
}

export enum CoVerifyApproveStatus {
  EXPIRED = 'EXPIRED', // 过期(前端自定义，非后端定义)
  REJECT = 'UNPASS', // 未通过
  PENDING = 'NO_OPERATE', // 等待中
  PASS = 'PASS', // 通过
}

export const CoVerifyInviteeTypeMap = Object.freeze({
  [CoVerifyInviterType.SameCompany]: {
    key: 'sso_web_same_company_colleage',
    text: '同企业同事',
  },
  [CoVerifyInviterType.Colleague]: {
    key: 'sso_web_same_team_colleage',
    text: '同组同事',
  },
  [CoVerifyInviterType.Leader]: {
    key: 'sso_web_immediate_supervisor',
    text: '直属上级',
  },
  [CoVerifyInviterType.AccountOwner]: {
    key: 'sso_web_account_manager',
    text: '账号负责人',
  },
});
