import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { deleteEmptyValue } from '@utils/tools';
import { getUrlParams } from '@utils/url';
import { getFixedSearchParams } from './utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IJumpOpts {
  isAppendQuery?: boolean; // 是否默认将，当前url上locale client_id等追加到目标url
}

interface Opts {
  state?: object;
  replace?: boolean;
  isDeleteEmptyValue?: boolean;
  isAppendQuery?: boolean; // 是否默认将，当前url上locale client_id等追加到目标url
}

interface ICommonQueryParams {
  locale: string;
  client_id: string;
  redirect_uri: string;
  // clientId?: string;
  // ssoGrayTag?: string;
  [key: string]: string;
}

/**
 * @description: 从当前url获取需要追加的query参数
 * @return {*}
 */
function getAppendQuery(): ICommonQueryParams {
  // 是否追加默认参数
  const currentQueryInfo: ICommonQueryParams = getUrlParams();
  const { locale, client_id, redirect_uri } = currentQueryInfo || {};
  const tempObj: ICommonQueryParams = {
    locale,
    // eslint-disable-next-line camelcase
    client_id,
    // eslint-disable-next-line camelcase
    redirect_uri,
  };
  return deleteEmptyValue(tempObj) as ICommonQueryParams;
}

function appendQuery(query) {
  const { locale, client_id, redirect_uri } = getAppendQuery();
  const newQuery = { ...(query || {}) };
  if (locale) {
    newQuery.locale = locale;
  }
  // eslint-disable-next-line camelcase
  if (client_id) {
    // eslint-disable-next-line camelcase
    newQuery.client_id = client_id;
  }
  // eslint-disable-next-line camelcase
  if (redirect_uri) {
    // eslint-disable-next-line camelcase
    newQuery.redirect_uri = redirect_uri;
  }
  return newQuery;
}

// 统一处理url的方法
function dealUrl(url: string, opts?: IJumpOpts): string {
  const { isAppendQuery = true } = opts || {};
  // 检查 URL 是否包含查询字符串
  const [baseUrl, queryStringPart] = url.split('?');
  // 解析查询字符串
  let parsed = queryString.parse(queryStringPart || '');
  if (isAppendQuery) {
    parsed = appendQuery(parsed);
  }
  // 生成新的查询字符串
  const newQueryString = queryString.stringify(parsed);
  // 返回新的完整 URL
  return `${baseUrl}${newQueryString ? `?${newQueryString}` : ''}`;
}

/**
 * @description: window.location.href 打开链接
 * @param {string} url
 * @param {IJumpOpts} opts
 */
function jump(url: string, opts?: IJumpOpts) {
  const target = dealUrl(url, opts);
  window.location.href = target;
}
function jumpReplace(url: string, opts?: IJumpOpts) {
  const target = dealUrl(url, opts);
  window.location.replace(target);
}

/**
 * @description: window.open 打开链接
 * @param {string} url
 * @param {IJumpOpts} opts
 */
function jumpOpen(url: string, searchParams: Record<string, any>) {
  const params = { ...(searchParams || {}), ...getFixedSearchParams() };

  const queryStr = queryString.stringify(params);

  const target = `${url}${queryStr ? `?${queryStr}` : ''}`;
  window.open(target, '_blank');
}

export { jump, jumpReplace, jumpOpen };

function goFn(navigate: any, pathname: string, queryInfo?: SSOAuthFeGlobal.Obj, opts?: Opts) {
  if (!pathname || typeof pathname !== 'string' || pathname.includes('?')) {
    console.error('pathname 为无效值');
    return;
  }
  let newQueryInfo = { ...(queryInfo || {}) };
  const { isDeleteEmptyValue, isAppendQuery = true } = opts || {};
  if (isAppendQuery) {
    newQueryInfo = appendQuery(newQueryInfo);
  }
  let newUrl = pathname;
  if (newQueryInfo && isDeleteEmptyValue) {
    // 过滤一下空值
    newQueryInfo = deleteEmptyValue(newQueryInfo);
  }
  const queryStr = newQueryInfo ? queryString.stringify(newQueryInfo) : '';
  if (queryStr) {
    newUrl = `${pathname}?${queryStr}`;
  }
  const { replace = false, state } = opts || {};
  navigate(newUrl, { replace, state });
}

const defaultGoNum = 0;
let goNum = defaultGoNum;

/**
 * @description: goNum不为0就说明登录过了,回到登录页需要一个新的ctxId,所以回到登录页刷新页面
 * @return {*}
 */
export function isNotGoNumDefaultFn() {
  return goNum !== defaultGoNum;
}

/**
 * @description: 跳转本单页项目页面方法
 */
export default function useGo() {
  const navigate = useNavigate();
  function go(pathname: string, queryInfo?: SSOAuthFeGlobal.Obj, opts?: Opts) {
    const newOpts = { ...opts, replace: false };
    goNum++;
    goFn(navigate, pathname, queryInfo, newOpts);
  }
  function replace(pathname: string, queryInfo?: SSOAuthFeGlobal.Obj, opts?: Opts) {
    const newOpts = { ...opts, replace: true };
    goFn(navigate, pathname, queryInfo, newOpts);
  }
  function goForward(num?: number) {
    navigate(num || 1);
  }
  function goBack(num?: number) {
    navigate(num || -1);
  }
  return {
    go,
    replace,
    goForward,
    goBack,
  };
}
