export enum ELoginPageInitStatus {
  pending = 'pending',
  success = 'success',
  fail = 'fail',
}
export interface ILoginPageErrorInfo {
  errorCode: number;
  errorMsg: string;
  traceId: string;
}
export interface ISendSmsCodeParams {
  interCode?: string; //如果为 null，默认 86
  mobile: string | null; //用户输入的手机号，如果为 null，会根据当前账号绑定的手机号来发送
}

export interface IVerifySmsCodeParams {
  smsCode: string;
}

export interface ISendVoiceCodeParams {
  interCode?: string; //如果为 null，默认 86
  mobile: string | null; //用户输入的手机号，如果为 null，会根据当前账号绑定的手机号来发送
}

export interface IVerifyVoiceCodeParams {
  voiceCode: string;
}

export enum RequestActionSource {
  LOGIN = 'login',
  SEND_CODE = 'sendCode',
}

export interface IAccountListItem {
  entName: string; // 企业名称
  // name: string; // 姓名
  mis: string; // mis 号
  workEmail: string; // 工作邮箱
  empId: string; // 账号 ID
  // mobile: string; // 手机号
  // interCode: string;
}
