import React from 'react';
import axios, { AxiosHeaders, InternalAxiosRequestConfig, AxiosRequestConfig } from 'axios';
import { message as messageDialog, Button } from '@ss/mtd-react';
import ApiError from '@src/types/apiError';
import { addAjaxError } from './owl';
import { ERROR_CODES } from '@common/errorCode';
import { IRequestBaseRes } from '@src/types/request';
import { i18nClient } from '@sailor/i18n-web';
import { getLoginUrl, getLocale } from '@utils/utils';
import { isObject } from '@utils/tools';
import { IQueryParams, getUrlParams } from './url';
import { getH5Fingerprint } from '@utils/ssoGuard';

/*
 * @see Full config:  https://www.axios-http.cn/docs/interceptors
 */
const config = {
  timeout: 10000, // Timeout
  withCredentials: true, // Check Cross-Site Access-Control
};

const instance = axios.create(config);

// Add request interceptor
instance.interceptors.request.use(
  // Do something before request is sent
  (request: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> => {
    const query = getUrlParams();
    const { clientId, client_id, ssoGrayTag, redirect_uri } = (query as IQueryParams) || {};
    const { url } = request || {};
    const isNotAddh5Fingerprint = ['/assistance/v1/inviteeApprove', '/assistance/v1/processInfo'].some((item) =>
      url?.includes(item),
    );
    const headers = new AxiosHeaders({
      'Content-Type': 'application/json',
      locale: getLocale(),
      // eslint-disable-next-line camelcase
      ssoGrayTag: clientId || client_id || ssoGrayTag || '', // 请求中header带上clientid, 跳转链接带上client_id或clientId或clientid, 用于sso灰度.
      // eslint-disable-next-line camelcase
      clientId: clientId || client_id || ssoGrayTag || '', // TODO ssoGrayTag这个参数还要不要? 有了clientId是否还有ssoGrayTag这个存在的意义
      // eslint-disable-next-line camelcase
      redirectUri: redirect_uri || '',
      // eslint-disable-next-line camelcase
      h5Fingerprint: isNotAddh5Fingerprint ? '' : getH5Fingerprint(), // 风控参数
    });
    return { ...request, headers };
  },
  // Do something with request error
  (error: any) => Promise.reject(error),
);

// Add response interceptor
instance.interceptors.response.use(
  // Do something with response data
  (response) => response.data,
  // Do something with response error
  (error) => Promise.reject(error),
);

interface IOptions {
  /**
   * @description: 路由前缀
   */
  prefix?: string;
  /**
   * @description: 超时时间
   */
  timeout?: number;
  /**
   * @description: 是否显示错误弹窗, 默认是
   */
  isShowErrorToast?: boolean;
}

/**
 * 数据请求
 * @param Object Irequest
 */
const request = async (
  url: string,
  method: string,
  params: Record<string, unknown>,
  options?: IOptions,
): Promise<any> => {
  const { prefix = '', timeout, isShowErrorToast = true } = options || {};
  try {
    const paramsObj: AxiosRequestConfig = {
      url,
      method,
      data: params,
      // timeoutErrorMessage: '',
    };
    if (prefix) {
      paramsObj.baseURL = prefix;
    }
    if (timeout) {
      paramsObj.timeout = timeout;
    }
    const res: IRequestBaseRes = await instance.request(paramsObj);
    const { code, ctxId, action } = res || {};
    if (code === ERROR_CODES.COMMON.SUCCESS && isObject(action)) {
      if (ctxId) {
        action.ctxId = ctxId;
      }
      return action;
    }
    throw res;
  } catch (error: unknown) {
    const isNetworkError = error instanceof Error;
    let code = ERROR_CODES.COMMON.CATCH_ERROR;
    let message = '';
    let traceId = '';
    if (isNetworkError) {
      const { message: _message } = error;
      message = _message;
    } else {
      const errorObj = error as IRequestBaseRes;
      const { code: _code, msg, traceId: _traceId } = errorObj || {};
      code = _code;
      message = (msg || '').slice(0, 600);
      traceId = _traceId;
    }
    addAjaxError(
      {
        name: `接口异常|${code}|${url}`,
        msg: message,
      },
      { tags: { traceId } },
    );
    if (isShowErrorToast) {
      let msgInfo: string | React.ReactNode = message || i18nClient.t('sso_web_request_except', '请求异常');
      if (isNetworkError) {
        msgInfo = (
          <>
            <span>
              {`code:${code} traceId:${traceId} ${message}` || i18nClient.t('sso_web_request_except', '请求异常')}
            </span>
            <Button
              type="primary"
              shape="text"
              style={{ marginLeft: '24px', height: '18px' }}
              onClick={() => {
                location.replace(getLoginUrl());
              }}
            >
              {i18nClient.t('sso_web_back_login_page', '返回登录页')}
            </Button>
          </>
        );
      }
      messageDialog.error({
        className: 'request-error-message',
        duration: 4000,
        message: msgInfo,
      });
    }
    throw new ApiError(code, message, traceId);
  }
};

export default {
  get: (url: string, params?: any, options?: IOptions) =>
    request(url, 'GET', params ? { ...params } : undefined, options),
  post: (url: string, params?: any, options?: IOptions) =>
    request(url, 'POST', params ? { ...params } : undefined, options),
};
