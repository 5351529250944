import { ELanguage } from '@src/types/language';

/**
 * @description: 目前支持的语言列表
 */
export const LangList = [
  { label: '简体中文', value: ELanguage.zh, iconfont: 'icon-jiantizhongwen' },
  { label: '繁體中文', value: ELanguage['zh-HK'], iconfont: 'icon-fantizhongwen' },
  { label: 'English', value: ELanguage.en, iconfont: 'icon-yingwen' },
  // { label: '阿拉伯语', value: ELanguage.ar, iconfont: 'icon-yingwen' },
] as const;

/**
 * @description: 目前支持的语言
 */
export const supportLangList = LangList.map((item) => item.value);

// 支持的语言类型
export type SupportLangType = (typeof supportLangList)[number];
