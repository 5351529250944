// 错误码文档https://km.sankuai.com/collabpage/2392617563#id-%E4%BA%8C%E3%80%81%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88%E7%9A%84%E8%AE%A8%E8%AE%BA%E4%B8%8E%E5%88%86%E6%9E%90
export const ERROR_CODES = {
  /**
   * @description: 100，通用类
   */
  COMMON: {
    /**
     * @description: 成功
     */
    SUCCESS: 1000200,
    /**
     * @description: 服务端未知异常，兜底的一个错误，如果能知道明确的业务错误，请尽量不要使用本代码
     */
    ERROR: 1000500,
    /**
     * @description: 前端请求catch错误
     * @return {*}
     */
    CATCH_ERROR: 0,
    // 操作太频繁
    OPERATION_TOO_FREQUENT: 1500005,
    /**
     * 流程中断
     * 登录会话中断，登录过程中的业务 ctxId 丢失或失效，可能是长时间未登录
     */
    PROCESS_INTERRUPT: 1509999,
    /**
     * @description: 一手机多账号
     */
    SELECT_ACCOUNT: 3010001,
  },
  /**
   * @description: 账密验证流程
   */
  ACCOUNT: {
    /**
     * @description: 账号或密码错误
     */
    ACCOUNT_OR_PASSPORT_ERROR: 1010000,
    /**
     * @description: 首次登录需改密码
     */
    FIRST_LOGIN: 1010001,
    /**
     * @description: 密码过期
     */
    PASSPORT_EXPIRED: 1010002,
    // // 多账号的情况
    // MULTI_ACCOUNT: 3010001,
  },
  IMAGE: {
    /**
     * @description: 图片验证成功
     */
    IMAGE_VERIFY_SUCCESS: 1020000,
    /**
     * 图片验证码获取成功，携带图片 url
     */
    IMAGE_REQUEST_CODE_REFRESHED: 1020001,
  },
  SLIDER: {
    /**
     * @description: 滑块验证成功
     */
    SLIDER_VERIFY_SUCCESS: 1030000,

    // 返回 YODA 滑块的 requestCode 成功
    SLIDER_REQUEST_CODE_REFRESHED: 1030001,
  },
  SECOND_AUTH: {
    /**
     * @description: 需要二次认证1500002
     */
    NEED_SECOND_AUTH: 1500002,
  },
  /**
   * @description: 语言验证码流程
   */
  VOICE: {
    /**
     * @description: 手机语音验证码发送成功 1040000
     */
    VOICE_SEND_SUCCESS: 1040000,
    /**
     * @description: 语音验证码发送失败 1040001
     */
    VOICE_SEND_ERROR: 1040001,
    /**
     * @description: 风控拦截 1040002
     */
    VOICE_SEND_RISK_DENY: 1040002,
    /**
     * @description: 语音验证码过于频繁 1040003
     */
    VOICE_SEND_TOO_FREQUENCY: 1040003,
    /**
     * @description: 语音验证码错误，请重新验证 1040004
     */
    VOICE_VERIFY_ERROR: 1040004,
    /**
     * @description: 语音验证码错误，请重新验证 1049999
     */
    VOICE_SERVICE_ERROR: 1049999,
  },
  /**
   * @description: 手机号验证流程
   */
  SMS: {
    /**
     * @description: 手机验证码发送成功 1050000
     */
    SMS_SEND_SUCCESS: 1050000,
    /**
     * @description: 手机验证码发送失败 1050001
     */
    SMS_SEND_ERROR: 1050001,
    /**
     * @description: 风控拦截 1050002
     */
    SMS_SEND_RISK_DENY: 1050002,
    /**
     * @description: 手机验证码过于频繁 1050003
     */
    SMS_SEND_TOO_FREQUENCY: 1050003,
    /**
     * @description: 手机验证码错误，请重新验证 1050004
     */
    SMS_VERIFY_ERROR: 1050004,
    /**
     * @description: 手机验证码服务当前不可用 1059999
     */
    SMS_SERVICE_ERROR: 1059999,
  },
  EMAIL: {
    /**
     * 发送成功 1050000
     */
    SEND_SUCCESS: 1110000,
    /**
     * 1059999（服务错误）
     */
    SERVICE_ERROR: 1059999,
    /**
     * 多账号需要选择账号登录
     */
    SELECT_ACCOUNT: 3060003,
  },
  QR_CODE: {
    /**
     * @description: 1060001 二维码已过期，请刷新后重试,(用户扫了码但是没有点确认,5分钟后过期)
     */
    QR_CODE_EXPIRED: 1060001,
    /**
     * @description: 1060003 确认成功
     */
    QR_CODE_SUCCESS: 1060003,
    /**
     * @description: 1060008 其他tab页登录成功,已有TGC
     */
    QR_CODE_LOGGED_IN: 1060008,
  },
  // 辅助认证
  CO_VERIFY: {
    // 请求成功
    REQUEST_SUCCESS: 1100000,
    // 审批未通过 （DATA 需要重新发起）
    REJECT: 1100005,
    // 过期 （TIP 需要重新发起）
    EXPIRED: 1100007,
    // 不可重复使用 （ TIP 需要重新发起）
    USED: 1100008,
    // 等待中
    WAITING: 1100009,
    // 通过
    PASS: 1500000,
  },
  MOA_MIS_CHECK: {
    /**
     * @description: 审批已发送给MOA登录mis 3080000
     */
    WAIT: 3080000,
    /**
     * @description: MOA登录mis拒绝 3080001
     */
    REJECT: 3080001,
    /**
     * @description: MOA审批服务不可用,无法联系用户 3089999
     */
    ERROR: 3089999,
  },
};
