import { getDefaultLang, changeCurrentLanguage, isInLangValueListFn, setLockLanguage } from './i18n';
import { supportLangList, SupportLangType } from '@common/i18n';
import { getUrlParams } from './url';
import { ssoSessionStorage } from './storage';

/**
 * @description: 判断是否为大象环境,是大象则读取大象设置的语言来设置默认语言
 */
export function getDaXiangLang() {
  const { userAgent } = navigator;
  const daXiangUaReg = new RegExp(`dx_la\\/(${supportLangList.join('|')})$`, 'i');
  const daxiangUAMatch = userAgent.match(daXiangUaReg);
  if (userAgent.indexOf('DXShell') > -1 && daxiangUAMatch && daxiangUAMatch.length > 0) {
    const daxiangLang = daxiangUAMatch[1] as string;
    // 现在和大象枚举是一致的(zh|zh-HK|en),以后大象枚举不一致了这里需要映射
    if (isInLangValueListFn(daxiangLang)) {
      return daxiangLang;
    }
  }
  return null;
}

/**
 * @description: 初始化url语言
 * @return {*}
 */
export function setDefaultLanguage() {
  const localeInSearch = getUrlParams('locale') as string;
  if (localeInSearch && isInLangValueListFn(localeInSearch)) {
    // 设置语言参数,给http请求header头用
    setLockLanguage(true);
    ssoSessionStorage.setItem('locale', localeInSearch);
    return;
  }

  let localeInSession: null | string = null;
  localeInSession = ssoSessionStorage.getItem('locale');
  if (localeInSession && isInLangValueListFn(localeInSession)) {
    setLockLanguage(true);
    changeCurrentLanguage(localeInSession as SupportLangType);
    return;
  }

  // 判断是否大象环境,是大象环境,当没有语言参数时需要获取大象的语言来设置登录页语言,移动端大象是用的native登录走不到这里
  const daxiangLang = getDaXiangLang();
  if (daxiangLang) {
    setLockLanguage(true);
    changeCurrentLanguage(daxiangLang as SupportLangType);
    return;
  }
  // @ts-ignore
  const defaultLang: SupportLangType = getDefaultLang();
  changeCurrentLanguage(defaultLang);
}
