export enum ELanguage {
  zh = 'zh',
  en = 'en',
  ar = 'ar', // 阿语
  'zh-cn' = 'zh-cn',
  'zh-CN' = 'zh-CN',
  'en-US' = 'en-US',
  'en-us' = 'en-us',
  'es-ES' = 'es-ES',
  'es-es' = 'es-es',
  'zh-HK' = 'zh-HK',
  'zh-TW' = 'zh-TW',
}

export interface ICountry {
  code?: string;
  name?: string;
}
