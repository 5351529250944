import React, { createContext, useContext, useState, useEffect } from 'react';
import { PageConfigType, IAppends, IRequestCommonAction } from '@src/types/request';
import { ELoginPageInitStatus, ILoginPageErrorInfo } from '@src/types/common';
import { resetLanguageAndRender } from '@utils/resetLanguage';
import { getUrlParams } from '@utils/url';
import { SupportLangType } from '@common/i18n';

const defaultLoginPageErrorInfo: ILoginPageErrorInfo = {
  errorCode: -1,
  errorMsg: '',
  traceId: '',
};

// 创建一个GlobalContext对象
const GlobalContext = createContext<{
  /**
   * @description: 是否获取到了页面配置参数
   */
  isGetResAction: boolean;
  loginPageInitStatus: ELoginPageInitStatus;
  loginPageErrorInfo: ILoginPageErrorInfo;
  locale?: SupportLangType;
  action?: IRequestCommonAction | null;
  pageConfigs?: PageConfigType;
  appends?: IAppends;
  isHideLogoAndLangInMobile: boolean;
  updateResAction: Function;
  updateLocale: Function;
  updateLoginPageInitStatus: Function;
  updateIsHideLogoAndLangInMobile: Function;
}>({
  isGetResAction: false,
  loginPageInitStatus: ELoginPageInitStatus.pending,
  loginPageErrorInfo: defaultLoginPageErrorInfo,
  isHideLogoAndLangInMobile: false,
  updateResAction: () => {},
  updateLocale: () => {},
  updateLoginPageInitStatus: () => {},
  updateIsHideLogoAndLangInMobile: () => {},
});

// 创建一个GlobalContextProvider组件, 这里只做数据传递,不做逻辑控制
export const GlobalContextProvider = ({ children }: { children: any }) => {
  const { locale: _locale } = getUrlParams();
  // 公共需要上下文传递的参数
  const [resAction, setResAction] = useState<IRequestCommonAction>();
  const [loginPageInitStatus, setLoginPageInitStatus] = useState<ELoginPageInitStatus>(ELoginPageInitStatus.pending);
  const [loginPageErrorInfo, setLoginPageErrorInfo] = useState<ILoginPageErrorInfo>(defaultLoginPageErrorInfo);
  const [locale, setLocale] = useState<SupportLangType>(_locale);
  const { payload } = resAction || {};
  const { data: pageConfigs, appends } = payload || {};

  //  在异步操作（如 setTimeout、Promise 回调、async/await）和生命周期方法中这里有可能不是批处理的,先塞数据再改true
  const updateResAction = (res) => {
    setResAction(res);
  };
  const updateLocale = (newLanguage) => {
    setLocale(newLanguage);
  };
  const updateLoginPageInitStatus = (status, errorInfo?: ILoginPageErrorInfo) => {
    if (status === ELoginPageInitStatus.fail) {
      setLoginPageErrorInfo(errorInfo || defaultLoginPageErrorInfo);
    } else {
      setLoginPageErrorInfo(defaultLoginPageErrorInfo);
    }
    setLoginPageInitStatus(status);
  };

  // 登录页需要上下文传递的参数
  const [isHideLogoAndLangInMobile, setIsHideLogoAndLang] = useState(false);
  const updateIsHideLogoAndLangInMobile = (bool) => {
    setIsHideLogoAndLang(bool);
  };

  useEffect(() => {
    // 当浏览器回退时,更新语言和url上的语言一致
    const handlePopState = () => {
      const { locale: locale2 } = getUrlParams();
      if (locale === locale2) {
        return;
      }
      resetLanguageAndRender(locale2, updateLocale, false);
    };

    // 监听 popstate 事件
    window.addEventListener('popstate', handlePopState);

    // 清理事件监听器
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [locale]);

  // 是否获取到了页面配置参数
  const isGetResAction = !!resAction;
  return (
    <GlobalContext.Provider
      value={{
        loginPageInitStatus,
        loginPageErrorInfo,
        isGetResAction,
        action: resAction,
        locale,
        pageConfigs,
        appends,
        isHideLogoAndLangInMobile,
        updateResAction,
        updateLocale,
        updateLoginPageInitStatus,
        updateIsHideLogoAndLangInMobile,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

// 创建一个自定义hook来使用Global context
export const useGlobalContext = () => useContext(GlobalContext);
