import ismobilejs from 'ismobilejs';
import { debug } from './debug';

// 是否为对象(下面的方法有依赖，勿删)
export function isObject(obj: any) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

// 是否为空对象(下面的方法有依赖，勿删)
export function isEmptyObject(data: any) {
  try {
    return JSON.stringify(data) === '{}';
  } catch (e) {
    return false;
  }
}

export function isIpadCheck() {
  /*
   * ipad环境判断更新
   * iOS pre 13 以前以ua作判断，13后以platform及maxTouchPoints做判断
   */
  const isIPad =
    navigator.userAgent.match(/(iPad)/) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  return isIPad;
  // 作者：CocoaKier
  // 链接：https://juejin.cn/post/6865658712665620494
  // 来源：稀土掘金
  // 著作权归作者所有。商业转载请联系作者获得授权，非商业转载请注明出处。
}

// 是否移动端环境
export function isMobileFn() {
  const ua = navigator.userAgent.toLowerCase();
  const isMobile = ismobilejs().any;
  const isMobileUa =
    /(MOMA|ipad|pad|iphone|IUC|android|Touch|JUC|Sensation|AiMeituan|Windows Phone OS|ZuneWP7|Symbian|Series40|UCWEB|shark)/.test(
      ua,
    );
  return isMobile || isMobileUa;
}

// 是否在移动端
export const isMobile = isMobileFn();
debug('isMobile', isMobile);

/**
 * @description: 过滤对象中的null和undefined
 * @param {*} obj
 * @return {*}
 */
export function filterNullAndUndefined(obj: any) {
  if (!isObject(obj) || isEmptyObject(obj)) {
    return {};
  }
  const newObj: SSOAuthFeGlobal.Obj = {};
  Object.keys(obj).forEach((it) => {
    const value = obj[it];
    if (value !== undefined && value !== null) {
      newObj[it] = value;
    }
  });
  return newObj;
}

/**
 * @description: 返回有值的对象
 * @param {SSOAuthFeGlobal} queryInfo
 * @return {*}
 */
export function deleteEmptyValue(queryInfo: SSOAuthFeGlobal.Obj) {
  const formatQueryInfo: SSOAuthFeGlobal.Obj = {};
  Object.keys(queryInfo || {}).forEach((key) => {
    const queryValue = queryInfo[key];
    if (queryValue) {
      formatQueryInfo[key] = queryValue;
    }
  });
  return formatQueryInfo;
}

/**
 * @description: 对象转数组
 * @return {{key: string, value:any}[]}
 */
export function object2Array(obj: SSOAuthFeGlobal.Obj) {
  if (!isObject(obj)) return [];
  const arr: { key: string; value: any }[] = [];
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      arr.push({ key, value: obj[key] });
    }
  }
  return arr;
}

export function escapeHtml(rawText: string): string {
  return (rawText || '')
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;');
}
