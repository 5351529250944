import queryString from 'query-string';

// 获取queryString.parse的返回类型
type QueryObjType = ReturnType<typeof queryString.parse>;
let queryObj: QueryObjType;

// Debug输出
export const debug = (...args) => {
  if (!queryObj) {
    queryObj = queryString.parse(window.location.search) || {};
  }
  const isDebug = Boolean(queryObj.debug);
  if (isDebug || location.hostname === 'localhost') {
    console.debug(...args);
  }
};
