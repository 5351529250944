// FlowControlInterrupt用来中断代码执行，不是错误异常
export default class FlowControlInterrupt implements SSOAuthFeGlobal.IFlowControlInterrupt {
  // 声明一个可以是字符串或任意类型的message属性
  message: string | any;

  // 构造函数，接收一个message参数用于初始化类的message属性
  constructor(message: string | any) {
    this.message = message;
  }
}
