import Cookies from 'js-cookie';
import { getI18nCurrentLocale } from '@utils/i18n';
import { i18nClient } from '@sailor/i18n-web';
import { ELanguage } from '@src/types/language';
import { SupportLangType } from '@common/i18n';
import { getUrlParams } from './url';
import { isDevelopment, isTest, isProduction, isStaging } from './env';
import { ssoSessionStorage } from './storage';
import { ILanguageConfig } from '@src/types/request';
import { ERROR_CODES } from '@common/errorCode';
import queryString from 'query-string';
import { pathnameInfo, ApiPrefix } from '@common/const';
import { debug } from './debug';

// 获取当前语言
export const getLocale = () => (getUrlParams('locale') || getI18nCurrentLocale() || ELanguage.zh) as SupportLangType;
debug('getLocale', getLocale());

/**
 * @description: 获取当前语言配置参数
 * @return {*}
 */
export const getCurrentLanguageConfig = (supportLanguages) => {
  const locale = getLocale();
  const currentLanguageConfig: ILanguageConfig = supportLanguages?.[locale];
  return currentLanguageConfig || {};
};

export const isBlank = (value: any) => {
  if (value === null || value === undefined || (typeof value === 'number' && isNaN(value))) {
    return true;
  } else if (typeof value === 'string' && value.trim() === '') {
    return true;
  }
  return false;
};

// 固定查询参数数组
export const FixedSearchParamsList = ['client_id', 'redirect_uri'] as const;

export type FixedSearchParamsListType = (typeof FixedSearchParamsList)[number];

// /固定查询参数类型
export type FixedSearchParamType = {
  [key in FixedSearchParamsListType]?: string;
} & { locale?: string };

export function setClientIdAndRedirectUri() {
  const { client_id, redirect_uri } = getUrlParams();
  // eslint-disable-next-line camelcase
  if (client_id && redirect_uri) {
    ssoSessionStorage.setItem('client_id', client_id);
    ssoSessionStorage.setItem('redirect_uri', redirect_uri);
  }
}

/**
 * 获取固定查询参数
 * 如果url有，从url里，否则从sessionStorage里取（兼容某些没有url参数的场景，如错误页）
 * @returns 查询参数对象
 */
export const getFixedSearchParams = () => {
  const params: FixedSearchParamType = {};
  const originalQuery: Record<string, any> = queryString.parse(window.location.search);

  FixedSearchParamsList.forEach((item) => {
    const value = originalQuery[item];
    // TODO 如果有相同的key value是数组
    // 先url里取
    if (value && !isBlank(value) && !['undefined', 'null'].includes(value?.trim())) {
      params[item] = value;
    } else {
      // 否则从sessionStorage取
      try {
        const sessionValue = ssoSessionStorage.getItem(item);
        if (sessionValue && !isBlank(sessionValue) && !['undefined', 'null'].includes(sessionValue?.trim())) {
          params[item] = sessionValue;
        }
      } catch (error) {
        console.error('当前环境不支持sessionStorage：', error);
      }
    }
  });

  const locale = getLocale();
  if (locale) {
    params.locale = locale;
  }

  return params;
};

/**
 * @description: 获取登录页链接
 * @return {string}
 */
export function getLoginUrl() {
  const currentSearchParam = getFixedSearchParams();
  const searchStr = queryString.stringify(currentSearchParam);
  return `${window.location.origin}${pathnameInfo.login}${searchStr ? `?${searchStr}` : ''}`;
}

/**
 * @description: 获取登出链接
 * @return {string}
 */
export function getLogoutUrl() {
  const currentSearchParam = getFixedSearchParams();
  const searchStr = queryString.stringify(currentSearchParam);
  return `${window.location.origin}${ApiPrefix}/auth/logout${searchStr ? `?${searchStr}` : ''}`;
}

// 延时函数
export const sleep = (time: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, time);
  });

// 获取cookie值
export const getCookie = (key) => Cookies.get(key);
// const nameEQ = `${key}=`;
// const ca = document.cookie.split(';');
// for (let i = 0; i < ca.length; i++) {
//   let c = ca[i];
//   while (c?.charAt(0) === ' ') c = c.substring(1, c.length);
//   if (c?.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
// }
// return null;

// 获取Yoda环境变量
export const getYoDaEnv = () => {
  // const env = getCookie('captcha_yoda_env');
  // if (env === 'product' || env === 'prod') {
  //   return null;
  // }
  // if (isTest || isDevelopment) return 'test';
  // return env;
  if (isProduction) {
    return null;
  }
  if (isStaging) {
    return 'staging';
  }
  return 'test';
};

// 获取重定向文案
export const getRedirectText = (code: number | string): { title?: string; ok?: string; cancel?: string } => {
  const textMap = {
    // 首次登录修改密码
    [ERROR_CODES.ACCOUNT.FIRST_LOGIN]: {
      title: i18nClient.t('sso_web_change_passwd_first_login_title', '提示'),
      ok: i18nClient.t('sso_web_change_passwd_first_login_ok', '前往修改'),
    },
    // 密码过期需改密码
    [ERROR_CODES.ACCOUNT.PASSPORT_EXPIRED]: {
      title: i18nClient.t('sso_web_passwd_expired_title', '提示'),
      ok: i18nClient.t('sso_web_passwd_expired_ok', '前往修改'),
      cancel: i18nClient.t('sso_web_passwd_expired_cancel', '其他登录方式'),
    },
  };

  // 兜底文案
  return (
    textMap[code] || {
      title: i18nClient.t('sso_web_change_passwd_first_login_title', '提示'),
      ok: i18nClient.t('sso_web_confirm', '确定'),
      cancel: i18nClient.t('sso_web_cancle', '取消'),
    }
  );
};

export function getCtxId() {
  return getCookie('ctxId');
}

// 检查是否是移动设备屏幕
export const checkIsMobileScreen = () => window.innerWidth <= 600;

// 是否是本地开发环境
export const checkIsLocalDev = () => location.hostname === 'localhost' && (isTest || isDevelopment);

/**
 * 检查手机号
 * @param mobileNumber 手机号
 * @param countryCode 国家和地区代码
 * @returns 是否为合法的手机号
 */
export const checkMobile = (mobileNumber: string, countryCode?: string) => {
  debug('checkMobile: ', mobileNumber, countryCode);
  if (isBlank(mobileNumber)) return false;
  if (isBlank(countryCode)) return false;
  const realMobileNumber = mobileNumber.trim();
  const realCountryCode = countryCode?.trim();
  if (realCountryCode === '+86' || realCountryCode === '86') {
    return /^1\d{10}$/.test(realMobileNumber);
  }
  return /^\d+$/.test(realMobileNumber);
};

// 检查邮箱
export const checkEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return !isBlank(email) && emailRegex.test(email);
};

/**
 * 格式化剩余时间
 * @param restSecond 剩余秒数（秒）
 */
export const formatLifeTime = (restSecond: number) => {
  // 分钟
  const minutes = Math.floor(restSecond / 60);
  // 秒
  const seconds = restSecond % 60;
  if (minutes <= 0 && seconds < 0) {
    return '';
  }
  return `${minutes}:${String(seconds).padStart(2, '0')}`;
};

export function generateSimpleID() {
  // 获取当前时间戳（毫秒级）
  const timestamp = Date.now().toString(36); // 转换为 36 进制，缩短长度

  // 生成随机字符串
  const randomString = Math.random().toString(36).substring(2, 10); // 去掉 `0.`，取后面的字符

  // 组合时间戳和随机字符串
  const uuid = `${timestamp}-${randomString}`;

  return uuid;
}
