import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { useGlobalContext } from '@src/context';
import { ELoginPageInitStatus } from '@src/types/common';
import LanguageSelector from '@components/LanguageSelector';
import { If, Choose, When, Otherwise } from 'jsx-control-statements';
import Feedback from '@components/Feedback';
import { getCurrentLanguageConfig } from '@utils/utils';
import styles from './index.scss';

interface IProps {
  /**
   * @description: 在移动端中是否隐藏logo和语言选择
   */
  isHideLogoAndLangInMobileFromProps?: boolean;
  children: ReactNode;
  className?: string;
}

export default function PageWrap(props: IProps) {
  const { children, isHideLogoAndLangInMobileFromProps = true } = props;
  // const { t } = useTranslation();
  const { locale, loginPageInitStatus, isGetResAction, pageConfigs, isHideLogoAndLangInMobile } = useGlobalContext();
  const isHide = isHideLogoAndLangInMobileFromProps || isHideLogoAndLangInMobile;
  const { supportLanguages, imageBackgroundTheme } = pageConfigs || {};
  const currentLanguageConfig = getCurrentLanguageConfig(supportLanguages);
  const { logoUrl, mobileLogoUrl, imageUrl, imageBackgroundUrl } = currentLanguageConfig || {};
  return (
    <div
      key={locale} // 当切换语言时，刷新一下组件，把后端返回的提示语去掉
      className={classnames(styles['layout-page-wrap'], {
        [styles['layout-page-wrap-theme']]: isGetResAction,
      })}
      style={{
        backgroundImage: `url(${imageBackgroundUrl || ''})`,
        backgroundColor: `${imageBackgroundTheme || ''}`, // 兼容一下ie11不支持设置var(--color)语法,不至于显示默认美团黄搞的太离谱
      }}
    >
      <div
        className={classnames(styles['layout-page-wrap--logo-wrap'], {
          'hide-in-mobile': isHide,
        })}
      >
        <If condition={isGetResAction}>
          <img src={logoUrl} className={styles.logo} />
          <img src={mobileLogoUrl || logoUrl} className={styles['h5-logo']} />
        </If>
      </div>
      <div
        className={classnames(styles['layout-page-wrap--language-wrap'], {
          'hide-in-mobile': isHide,
        })}
      >
        <LanguageSelector />
      </div>
      <Choose>
        <When
          condition={
            loginPageInitStatus === ELoginPageInitStatus.success || loginPageInitStatus === ELoginPageInitStatus.pending
          }
        >
          <div className={classnames(styles['layout-page-wrap--content'], { [styles.top0]: isHide })}>
            <div className={styles['layout-page-wrap--banner-section']}>
              <If condition={isGetResAction}>
                <img src={imageUrl} />
              </If>
            </div>
            <div className={styles['layout-page-wrap--login-section']}>
              <div className={classnames(styles['layout-page-wrap--login-wrap'], 'global-modal-container')}>
                {children}
              </div>
              <div className={styles['layout-page-wrap--feedback']}>
                <Feedback />
              </div>
            </div>
          </div>
        </When>
        <Otherwise>{children}</Otherwise>
      </Choose>
    </div>
  );
}
