import queryString from 'query-string';
import { ELanguage } from '@src/types/language';
import { ssoSessionStorage } from '@utils/storage';
import { supportLangList, SupportLangType } from '@common/i18n';

export const LOCALE_KEY = 'locale';

export function getI18nCurrentLocale() {
  return ssoSessionStorage.getItem(LOCALE_KEY);
}

/**
 * @description: 是否在语言列表中
 * @param {string} lang
 * @return {boolean}
 */
export function isInLangValueListFn(lang: string) {
  for (let i = 0; i < supportLangList.length; i++) {
    if (supportLangList[i] === lang && lang) {
      return true;
    }
  }
  return false;
}

/**
 * @description: 将语言转换成标准语言格式
 * @param {*} lang
 * @return {*}
 */
export function transLang(lang) {
  if (/^zh-(HK|TW)/i.test(lang)) {
    return ELanguage['zh-HK'];
  }
  if (/^(en|es)-?/.test(lang)) {
    return ELanguage.en;
  }
  // 阿拉伯语(仅测试rtl用)
  if (/^(ar)-?/.test(lang)) {
    return ELanguage.ar;
  }
  return ELanguage.zh;
}

/**
 * @description: 根据浏览器语言设置初始化当前语言
 */
export function getDefaultLang() {
  return transLang(navigator.language);
}

/**
 * @description: sso语言参数转换为yoda的语言参数
 * @param {*} lang
 * @return {*}
 */
export function lang2yodaLang(lang: string) {
  // yoda国内版本目前仅支持中文 英文 日文
  switch (lang) {
    case ELanguage.en:
      return ELanguage.en;
    case ELanguage.zh:
    case ELanguage['zh-HK']:
    default:
      return ELanguage['zh-CN'];
  }
}

/**
 * @description: 修改url上的key value
 * @param {string} url
 */
export function updateUrlParameter(url: string, key: string, value: string) {
  // 检查 value 是否有效
  if (!value) {
    return url; // 如果 value 是空，返回原始 URL
  }

  // 检查 URL 是否包含查询字符串
  const [baseUrl, queryStringPart] = url.split('?');
  // 解析查询字符串
  const parsed = queryString.parse(queryStringPart || '');
  // 更新或添加参数
  parsed[key] = value;
  // 生成新的查询字符串
  const newQueryString = queryString.stringify(parsed);
  // 返回新的完整 URL
  return `${baseUrl}${newQueryString ? `?${newQueryString}` : ''}`;
}

/**
 * @description: 锁定当前语言，防止接口默认语言重置
 * @param {*} bool
 * @return {*}
 */
export function setLockLanguage(bool: boolean) {
  if (bool) {
    ssoSessionStorage.setItem('isLockLanguage', 'true');
  } else {
    ssoSessionStorage.clear('isLockLanguage');
  }
}

export function isLockLanguageFn() {
  return ssoSessionStorage.getItem('isLockLanguage') === 'true';
}

/**
 * @description: 修改当前语言
 * @param {string} language
 */
export function changeCurrentLanguage(language: SupportLangType) {
  if (!isInLangValueListFn(language)) return;
  // 设置语言参数,给http请求header头用
  ssoSessionStorage.setItem('locale', language);
  const url = updateUrlParameter(window.location.href, 'locale', language);
  if (window.history.replaceState) {
    window.history.replaceState({}, '', url);
    return;
  }
  window.location.replace(url);
}
