import React from 'react';
import { useTranslation } from '@sailor/i18n-web';
import { Button } from '@ss/mtd-react';
import { jump } from '@utils/jump';
import notFound from '@imgs/not_found.png';
import styles from './index.scss';
import { getLoginUrl } from '@utils/utils';
// import debounce from 'lodash/debounce';

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <div className={styles['page-not-found']}>
      <img src={notFound} alt="404" />
      <h3> {t('sso_web_page_not_found', '页面不存在')}</h3>
      <p style={{ padding: '0 20px' }}>
        {t('sso_web_page_not_found_return', '您访问的页面不存在，请返回登录页重试。')}
      </p>
      <Button
        className={styles['page-not-found--button']}
        type="primary"
        size="large"
        onClick={() => {
          jump(getLoginUrl());
        }}
      >
        {t('sso_web_back_login_page', '返回登录页')}
      </Button>
    </div>
  );
}
