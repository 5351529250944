// import { getUrlParams } from '@utils/url';
// import { ELanguage } from '@src/types/language';
import defaultConfig from '@src/layout/PageWrap/defaultConfig.json';
import { filterNullAndUndefined } from '@utils/tools';

export function resetTheme(res) {
  const pageConfigs = res?.payload?.data;
  const loginConfig = Object.assign(defaultConfig, filterNullAndUndefined(pageConfigs));
  const {
    /**
     * @description: 多语言切换按钮文字颜色
     */
    switchLanguageColor,
    /**
     * @description:  主题色配置——背景图颜色
     */
    imageBackgroundTheme,
    /**
     * @description:  主题色配置——logo背景图颜色
     */
    logoBackgroundTheme,
    /**
     * @description:  登录按钮配置——按钮背景颜色
     */
    btnBackground,
    /**
     * @description: // 登录按钮配置——按钮圆角值
     */
    btnBorderRadius,
    /**
     * @description: 输入框配置——按钮文字颜色
     */
    btnTextTheme,
    /**
     * @description: 输入框配置——输入框圆角
     */
    inputBorderRadius,
    /**
     * @description: 次要文字配置——次要文字颜色-> 指【忘记密码】对应老的项目textIconTheme
     */
    secondaryColor,
    /**
     * @description: 底部文字配置——底部文字颜色 -> 指【问题反馈】 对应老的项目textTheme,老项目选择语言也用的textTheme
     */
    bottomColor,
    /**
     * @description: 禁用鼠标右键目前（只有闪闪）
     */
    banRightMouseButton,
  } = loginConfig;
  const setProperty = document?.documentElement?.style?.setProperty?.bind(document.documentElement.style) || (() => {});
  setProperty('--sso-image-background-theme', imageBackgroundTheme);
  setProperty('--sso-logo-background-theme', logoBackgroundTheme);
  setProperty('--sso-switch-language-color', switchLanguageColor);
  setProperty('--sso-bottom-color', bottomColor);
  setProperty('--sso-btn-text-theme', btnTextTheme);
  setProperty('--sso-btn-background', btnBackground);
  setProperty('--sso-btn-border-radius', btnBorderRadius);
  setProperty('--sso-input-border-radius', inputBorderRadius);
  setProperty('--sso-secondary-color', secondaryColor);

  if (banRightMouseButton) {
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
  }
}
