import 'intl-pluralrules'; // 低端机兼容
import { i18nClient } from '@sailor/i18n-web';
import { getDefaultLang, LOCALE_KEY } from '@utils/i18n';
import resources from '../assets/locales/resources';

function updateTitle() {
  document.title = i18nClient.t('sso_web_sso_info', '统一登录中心');
}

/**
 * @description: 初始化项目多语言
 */
export function initI18nLocal() {
  i18nClient.init({
    ns: 'SSO_Web',
    fallbackLng: getDefaultLang(),
    resources,
    detection: {
      // 语言自动识别配置
      order: ['querystring', 'sessionStorage'], // 自动识别locale顺序
      lookupQuerystring: 'locale', // query 参数
      lookupSessionStorage: LOCALE_KEY, // sessionStorage key
      // caches: ['sessionStorage'], // 配置缓存，如果不需要换成，可配置为空数组
      caches: [], // 配置缓存，如果不需要换成，可配置为空数组
    },
    react: {
      useSuspense: false,
    },
  });
  updateTitle();
}

export function changeI18nLang(lang) {
  i18nClient.changeLanguage(lang, (err) => {
    if (err) {
      console.error('语言切换失败');
      return;
    }
    updateTitle();
  });
}
