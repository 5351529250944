let environment: null | Object = null;
function getEnvironment() {
  if (!environment) {
    environment = {};
  }
  return environment;
}

interface ILxParams {
  options?: null | object;
  valLab?: null | object;
}

interface ILxModuleClick extends ILxParams {
  bid: string;
}

interface ILxModuleView extends ILxParams {
  bid: string;
}

interface ILxPageView extends ILxParams {
  cid: string;
  env?: object;
}

/**
 * @description: MC点击埋点
 * @param {*} options
 * @param {*} bid 埋点id
 * @param {*} valLab 自定义需要传入的信息
 * @return {*}
 */
export const LxModuleClick = ({ options = null, bid, valLab = {} }: ILxModuleClick) => {
  if (!bid) return;
  window.LXAnalytics && window.LXAnalytics('moduleClick', bid, valLab, options);
};

/**
 * MV事件埋点
 * @param {string} options
 * @param {string} bid 埋点id
 * @param {object} valLab 自定义需要传入的信息
 */
export const LxModuleView = ({ options = null, bid, valLab = {} }: ILxModuleView) => {
  if (!bid) return;
  window.LXAnalytics && window.LXAnalytics('moduleView', bid, valLab, options);
};

/**
 * PV事件埋点
 * @param {string} options
 * @param {string} cid page id
 * @param {object} valLab 自定义需要传入的信息
 */
export const LxPageView = ({ cid, valLab = {}, env = {} }: ILxPageView) => {
  if (!cid) return;
  const newEnv = { ...getEnvironment(), ...env };
  window.LXAnalytics && window.LXAnalytics('pageView', valLab, newEnv, cid);
};
