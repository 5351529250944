import React from 'react';

interface PropsType {
  type?: string | undefined; // 图标类型
  className?: string; // 自定义类名
  active?: boolean; // 是否激活
  style?: any;
  [attr: string]: any; // 自定义属性
}

const prefixCls = 'iconfont'; // 图标字体标识符

export default function SsoIcon({ className, type, active, ...otherProps }: PropsType) {
  const realClassName = [className, prefixCls, `${type}`, active && 'active'].filter(Boolean).join(' ');
  return <i className={realClassName} {...otherProps} />;
}
