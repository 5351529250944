import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './utils/errorListener';
import React from 'react';
import { render } from 'react-dom';
// 设置url多语言
import { setDefaultLanguage } from '@utils/initLanguage';
import { initI18nLocal } from './i18nInit';
import { setDir } from './utils/setRtlByLanguage';
import { getLoginPageConfig } from '@src/api/loginPage';
import { resetTheme } from '@utils/resetTheme';
import Router from './router';
import { debug } from '@utils/debug';
import { pathnameInfo } from '@common/const';
import getInitSSOGuardPromise from '@utils/ssoGuard';
import { reportWebDeviceUuid } from '@utils/risk';
import './style/index.scss';

// 不请求配置信息的页面
const IgnoreConfigPath = [
  pathnameInfo.error,
  pathnameInfo['qr-code-confirm'],
  pathnameInfo['qr-code-error'],
  pathnameInfo['co-verify-audit'],
  pathnameInfo['co-verify-apply-result'],
  pathnameInfo['co-verify-audit-result'],
  pathnameInfo['feedback-help'],
];

// 语言的初始化
(() => {
  try {
    setDefaultLanguage();
    setDir();
    initI18nLocal();
  } catch (error) {
    console.error('国际化初始化语言失败', error);
  }
})();

// 提前请求配置信息
(async () => {
  // 风控相关参数
  reportWebDeviceUuid();
  debug('开始上报dfpId-index.tsx');
  // 等待dfpId生成完成
  await getInitSSOGuardPromise();
  if (!IgnoreConfigPath.some((item) => location.pathname === item)) {
    debug('开始调getLoginPageConfig-index.tsx');
    try {
      // 提前请求接口
      const res = await getLoginPageConfig();
      // 设置主题色，必须在页面重新渲染前设置， 不然会看见默认美团黄主题背景
      resetTheme(res);
    } catch (error) {
      console.error('getLoginPageConfig error', error);
    }
  }
})();

const renderFn = (Component: any) => {
  render(
    // <React.StrictMode> 是一个将开发环境中的潜在问题高亮显示的工具。它会激活一些额外的检查和警告，但不会影响生产构建。
    <React.StrictMode>
      <Component />
    </React.StrictMode>,
    document.getElementById('root') as HTMLElement,
  );
};

renderFn(Router);

if (module.hot) {
  module.hot.accept('./router', () => {
    const NextRouter = require('./router').default;
    renderFn(NextRouter);
  });
}
