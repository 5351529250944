import React from 'react';
import { useGlobalContext } from '@src/context';
import { getCurrentLanguageConfig } from '@utils/utils';
import FeedBackPure from './FeedBackPure';

interface IProps {
  className?: string; // 自定义类名
  color?: string; // 字体颜色
}

export default function Feedback(props: IProps) {
  const { pageConfigs } = useGlobalContext();
  const { supportLanguages, isShowBottomText } = pageConfigs || {};
  const { bottomText, bottomTextUrl } = getCurrentLanguageConfig(supportLanguages);
  const { color, className } = props;
  if (!isShowBottomText) return null;
  return <FeedBackPure bottomText={bottomText} bottomTextUrl={bottomTextUrl} color={color} className={className} />;
}
