export default class ApiError extends Error implements SSOAuthFeGlobal.IApiError {
  code?: number | string;
  traceId?: string;
  override stack?: string;

  constructor(code: number | string, message: string, traceId = '', stack = '') {
    super(message);
    this.code = code;
    this.traceId = traceId;
    this.stack = stack;
    Object.setPrototypeOf(this, ApiError.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }
  }
}

Object.setPrototypeOf(ApiError.prototype, Error.prototype);

ApiError.prototype.name = 'ApiError';
