// import { loadH5guardPromise } from '@utils/dom';
import { reportRandoIdApi } from '@src/api/risk';
import { ssoSessionStorage } from '@utils/storage';
import { LxModuleView } from '@utils/lxFn';
import { addJsError } from './owl';
import { pathnameInfo, SSOX_LOGIN } from '@common/const';

// async function getRandomId() {
//   const maxRetries = 2; // 最大重试次数
//   let attempts = 0; // 当前尝试次数

//   while (attempts < maxRetries) {
//     try {
//       const res = await getRandomIdApi();
//       return res?.data?.randomId; // 成功时返回随机 ID
//     } catch (error) {
//       attempts++;
//       // 如果达到了最大尝试次数，抛出异常
//       if (attempts === maxRetries) {
//         LxModuleView({
//           bid: 'b_oa_sdum84h5_mv',
//           valLab: { time: new Date().toLocaleString() },
//         });
//         throw new Error('获取随机串id接口调用失败');
//       }
//     }
//   }
// }

async function reportRandomId(randomId) {
  const maxRetries = 2; // 最大重试次数
  let attempts = 0; // 当前尝试次数

  while (attempts < maxRetries) {
    try {
      // eslint-disable-next-line camelcase
      await reportRandoIdApi(randomId);
      attempts = maxRetries;
      // 上报随机串成功埋点
      LxModuleView({
        bid: 'b_oa_89jliten_mv',
        // eslint-disable-next-line camelcase
        valLab: { time: new Date().toLocaleString(), random_id: randomId },
      });
    } catch (error) {
      console.error('上报随机串id失败', error);
      attempts++;
      // 如果达到了最大尝试次数，抛出异常
      if (attempts === maxRetries) {
        LxModuleView({
          bid: 'b_oa_p3a5tfzm_mv',
          // eslint-disable-next-line camelcase
          valLab: { time: new Date().toLocaleString(), random_id: randomId },
        });
      }
    }
  }
}

// function setSSOGuardRandomId(randomId) {
//   window.__ssoGuardRandomId__ = randomId;
//   // 将随机串id放在sessionStorage里,用于其他页面,加到请求的query里
//   ssoSessionStorage.setItem('__ssoGuardRandomId__', randomId);
// }

// export function getSSOGuardRandomId() {
//   return window.__ssoGuardRandomId__ || ssoSessionStorage.getItem('__ssoGuardRandomId__');
// }

// eslint-disable-next-line camelcase
function setH5Fingerprint(h5_fingerprint) {
  // eslint-disable-next-line camelcase
  window.h5_fingerprint = h5_fingerprint;
  ssoSessionStorage.setItem('h5_fingerprint', window.h5_fingerprint || '');
}

// eslint-disable-next-line camelcase
export function getH5Fingerprint() {
  // eslint-disable-next-line camelcase
  return window.h5_fingerprint || ssoSessionStorage.getItem('h5_fingerprint') || '';
}
const h5GuardDefaultOptions = {
  xhrHook: true,
  domains: ['ssosv.sankuai.com', 'ssosv.it.st.sankuai.com', 'ssosv.it.test.sankuai.com'],
};

function createH5Fingerprint() {
  if (!window.H5guard || !window.H5guard.init || !window.H5guard.getfp) {
    addJsError('创建h5_fingerprint异常', {
      tags: {
        h5guard: Boolean(window.H5guard),
        h5guardInit: Boolean(window.H5guard?.init),
        h5guardGetfp: Boolean(window.H5guard?.getfp),
      },
    });
    return;
  }
  window.H5guard.init(h5GuardDefaultOptions);
  // 生成h5_fingerprint
  // 这个方法必须先初始化
  // eslint-disable-next-line camelcase
  const h5_fingerprint = window.H5guard.getfp();
  setH5Fingerprint(h5_fingerprint);
}

// function createRandomId() {
//   if (!window || !window.H5guard || !window.H5guard.getSGRandom) {
//     addJsError('创建随机串id异常', {
//       tags: {
//         h5guard: Boolean(window.H5guard),
//         h5guardGetSGRandom: Boolean(window.H5guard?.getSGRandom),
//       },
//     });
//     return;
//   }
//   // 登录页判断有随机串id,没有需要生成随机串id, 其他页面不需要// 生成随机串Id
//   const randomId = window.H5guard.getSGRandom();
//   setSSOGuardRandomId(randomId);
//   reportRandomId(randomId);
// }

// async function initSSOGuard(h5GuardOptions = h5GuardDefaultOptions) {
//   if (getH5Fingerprint() && getSSOGuardRandomId()) return;
//   LxModuleView({
//     bid: 'b_oa_qj86pxcs_mv',
//     // eslint-disable-next-line camelcase
//     valLab: { time: new Date().toLocaleString() },
//   });
//   // 获取随机串是为了防止重复生成随机串,没有才生成,防止随机串突变导致同一个tgt对应了多个随机串dfpId
//   let randomId = null;
//   const getRandomIdPromise = getRandomId();
//   const _loadH5guardPromise = loadH5guardPromise();
//   try {
//     randomId = await getRandomIdPromise;
//   } catch (error) {
//     console.error(error);
//   }
//   await _loadH5guardPromise;
//   try {
//     const isLoginPage = location.pathname === pathnameInfo.login || location.pathname === SSOX_LOGIN;
//     if (randomId) {
//       setSSOGuardRandomId(randomId);
//     } else if (randomId !== null && isLoginPage) {
//       // 接口异常不生成随机串
//       // 登录页才生成随机串, 其他页面应该是经过了登录的, 能获取到随机串
//       createRandomId();
//     }
//     createH5Fingerprint();
//   } catch (error) {
//     console.error(error);
//   }
// }

async function createDfpId() {
  if (!window || !window.H5guard || !window.H5guard.getSGRandom) {
    addJsError('创建随机串id异常', {
      tags: {
        h5guard: Boolean(window.H5guard),
        h5guardGetSGRandom: Boolean(window.H5guard?.getSGRandom),
      },
    });
    return;
  }
  // 每次都生成,调接口cookie里没有u2dhn6k,后端就种上,有就直接忽略,后续所有请求后端直接从cookie里读取u2dhn6k
  const randomId = window.H5guard.getSGRandom();
  await reportRandomId(randomId);
}

async function initSSOGuard() {
  try {
    createH5Fingerprint();
    const isLoginPage = location.pathname === pathnameInfo.login || location.pathname === SSOX_LOGIN;
    // 登录页才生成dfpId
    if (isLoginPage) {
      await createDfpId();
    }
  } catch (error) {
    console.error('初始化ssoGuard异常', error);
  }
}

let initSSOGuardPromise: Promise<void> | null = null;

export default function getInitSSOGuardPromise() {
  if (!initSSOGuardPromise) {
    initSSOGuardPromise = initSSOGuard();
  }
  return initSSOGuardPromise;
}
