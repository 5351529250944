import React, { useEffect, useState } from 'react';
import { Button } from '@ss/mtd-react';
import { useTranslation } from '@sailor/i18n-web';
import ErrorIcon from '@imgs/error.png';
import styles from './index.scss';
import { checkIsLocalDev, FixedSearchParamsList, getLoginUrl, isBlank } from '@utils/utils';
import { If } from 'jsx-control-statements';
import { jump } from '@utils/jump';
import { debug } from '@utils/debug';
import queryString from 'query-string';

interface IProps {
  errorTitle?: string;
  errorMsg?: string;
  traceId?: string;
  hideReturnButton?: boolean; // 是否是访问控制页面
}

// 错误组件
// 如果当前页面不存在client_id和redirect_uri，则不展示返回登录页按钮
export default function Error(props: IProps) {
  const { errorTitle, errorMsg, traceId, hideReturnButton } = props;
  const { t } = useTranslation();
  const [showReturnButton, setShowReturnButton] = useState(false);

  // 在本地开发环境刷新ctxId
  const handleRefreshCtxIdButton = async () => {
    try {
      await fetch('/localhost/ctxId');
      window.location.reload();
    } catch (e) {
      debug('handleRefreshCtxIdButton: ', e);
    }
  };

  // 如果当前页面不存在client_id和redirect_uri，则不展示返回登录页按钮
  useEffect(() => {
    const originalQuery: Record<string, any> = queryString.parse(window.location.search);

    const shouldShowReturnButton = FixedSearchParamsList.every((item) => {
      let value = originalQuery[item];
      // 如果是数组，取最后一个值
      if (Array.isArray(value)) {
        console.warn(`Duplicate parameter type: ${item}`);
        value = value[value.length - 1];
      }
      if (value && !isBlank(value) && !['undefined', 'null'].includes(value?.trim())) {
        return true;
      }
      return false;
    });

    debug('shouldShowReturnButton: ', shouldShowReturnButton);

    setShowReturnButton(shouldShowReturnButton);
  }, []);

  return (
    <div className={styles['error-wrapper']}>
      <img className={styles['error-icon']} src={ErrorIcon} />
      <div className={styles['error-main']}>
        <If condition={errorTitle}>
          <div className={styles['error-title']}>{errorTitle}</div>
        </If>
        <div className={styles['error-msg']}>{errorMsg}</div>
        {traceId ? <span className={styles['error-trace']}>TraceId: {traceId}</span> : null}
        {hideReturnButton || !showReturnButton ? null : (
          <Button
            className={styles['error-button']}
            type="primary"
            onClick={() => {
              // 跳转到登录页
              jump(getLoginUrl());
            }}
            shape="text"
          >
            {t('sso_web_back_login_page', '返回登录页')}
          </Button>
        )}
        {checkIsLocalDev() ? (
          <Button className={styles['error-button']} type="primary" onClick={handleRefreshCtxIdButton}>
            刷新ctxID
          </Button>
        ) : null}
      </div>
    </div>
  );
}
