// This file was auto generated by resource-sync tools.

import ar_SSO_Web from './ar/SSO_Web.json';
import en_SSO_Web from './en/SSO_Web.json';
import zh_SSO_Web from './zh/SSO_Web.json';
import zhHK_SSO_Web from './zh-HK/SSO_Web.json';

const resources = {
  ar: {
    SSO_Web: ar_SSO_Web,
  },
  en: {
    SSO_Web: en_SSO_Web,
  },
  zh: {
    SSO_Web: zh_SSO_Web,
  },
  'zh-HK': {
    SSO_Web: zhHK_SSO_Web,
  },
};

export default resources;
