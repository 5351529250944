import Cookies from 'js-cookie';
import { getBrowserVersion } from '@utils/browser';
// import { addJsError } from '@utils/owl';
// import { md5 } from '@utils/crypto';
// import { loadFingerprint2Promise } from '@utils/dom';

/**
 * @description: 获取webDeviceUuid
 * @return {*}
 */
const collectUUIdPromise = () =>
  new Promise((resolve) => {
    if (!window.LXAnalytics) {
      resolve('');
      return;
    }
    setTimeout(() => {
      resolve('');
    }, 5000);
    window?.LXAnalytics('get', 'lxcuid', (data) => {
      const browserInfo = getBrowserVersion();
      const opt: Cookies.CookieAttributes = {
        path: '/',
        Secure: false,
      };
      const isHttps = location.protocol.match(/^https/);
      if (isHttps) {
        opt.Secure = true;
      }
      // 许多现代浏览器支持 SameSite 属性。以下是一些常见浏览器及其支持情况：
      // Chrome：从 76 版本开始。
      // Firefox：从 63 版本开始。
      // Safari：从 13 版本开始。
      // Edge：从 79 版本（基于 Chromium）开始。
      // Internet Explorer：不支持 SameSite。
      if (isHttps && (browserInfo?.chrome >= 80 || browserInfo?.firefox >= 63 || browserInfo?.safari >= 13)) {
        opt.sameSite = 'None';
      }
      // 种到cookie即可,后端自动获取,无需上报
      Cookies.set('webDeviceUuid', data, opt);
      resolve(data);
    });
  });

/**
 * @description: 获取webTokenInfo
 * //s3plus.meituan.net/v1/mss_e7b95138dc0d4c29858d76b37e6ebef7/sso-service/static/default.js
 * @return {string}
//  */
// const getwebTokenInfo = () => {
//   if (!window.Rohr_Opt || !window.Rohr_Opt.reload) {
//     addJsError('webTokenInfo获取失败');
//     return;
//   }
//   return window.Rohr_Opt.reload(window.location.origin);
// };

/**
 * @description: 获取指纹参数
 * //s3plus.meituan.net/v1/mss_29f02df7dcb14a83951095a86f204dfb/common-js/fingerprint2.js
 * @return {object | null}
 */
// const getFingerprint2Promise = async () => {
//   if (!window.Fingerprint2 || !window.Fingerprint2?.get) {
//     addJsError('fingerprint2获取失败');
//     return Promise.resolve(null);
//   }
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(null);
//     }, 5000);
//     window.Fingerprint2?.get(
//       {
//         excludes: {
//           enumerateDevices: true,
//         },
//       },
//       (components) => {
//         const moreComponents = {};
//         const values: any[] = [];
//         if (!Array.isArray(components) || components.length === 0) {
//           addJsError('fingerprint2生成数据为空');
//           return;
//         }
//         for (let i = 0; i < components.length; i++) {
//           const itemKey = components[i].key;
//           const itemValue = components[i].value;
//           if (itemKey === 'canvas') {
//             moreComponents[itemKey] = [itemValue[0], md5(itemValue[1])];
//           } else {
//             moreComponents[itemKey] = itemValue;
//           }
//           values.push(itemValue);
//         }
//         const webNewUuid = Cookies.get('webNewUuid');
//         if (!webNewUuid) {
//           const murmur = `${window.Fingerprint2?.x64hash128(values.join(''), 31)}_${new Date().getTime()}`;
//           const opt = {
//             path: '/',
//             expires: 365 * 100, // 原来是Fri, 31 Dec 9999 23:59:59 GMT, 我理解写一个超长的时间就行
//           };
//           Cookies.set('webNewUuid', murmur, opt);
//         }
//         resolve(moreComponents);
//       },
//     );
//   });
// };

export async function reportWebDeviceUuid() {
  try {
    await collectUUIdPromise();
  } catch (error) {
    console.error(error);
  }
}

// export async function loadJsAndReportWebTokenInfo() {
//   await loadCollectFingerprintPromise();
//   const webTokenInfo = getwebTokenInfo();
//   reportRisk({ webTokenInfo });
// }

// export async function loadJsAndReportWebNewUuid() {
//   await loadFingerprint2Promise();
//   const moreComponents = await getFingerprint2Promise();
//   const murmur = Cookies.get('webNewUuid');
//   reportRisk({ ...(moreComponents || {}), uuid: murmur });
// }
