import React from 'react';
import { Loading } from '@ss/mtd-react';

interface IStyle {
  // flexShrink: number;
  flexGrow: number;
  minWidth: string;
  display: string;
  alignItems: string;
  justifyContent: string;
  minHeight?: string | number;
}

export default function WaitLoading() {
  const style: IStyle = {
    flexGrow: 1, // 在PageWrap组件中用这个撑开高度
    minWidth: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%', // 不在PageWrap组件中,用这个撑开高度
  };
  return <Loading style={style} />;
}
