import React, { useState } from 'react';
import { Popover } from '@ss/mtd-react';
import { LangList } from '@common';
import { useGlobalContext } from '@src/context';
import { resetLanguageAndRender } from '@utils/resetLanguage';
import SsoIcon from '@components/sso-icon';
import classnames from 'classnames';
import { setLockLanguage } from '@utils/i18n';
import { getLocale } from '@utils/utils';
import styles from './index.scss';

// 语言选择器
const LanguageSelector = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isGetResAction, pageConfigs, updateLocale } = useGlobalContext();
  const { supportLanguages } = pageConfigs || {};
  const langArr = Object.keys(supportLanguages || {});
  const filterLangList = LangList.filter((item) => langArr.includes(item.value));
  const locale = getLocale();
  const currentLanguageInfo = filterLangList.find((item) => item.value === locale);
  if (!isGetResAction || !langArr || langArr.length === 0) return null;

  return (
    <div className={styles['com-language-selector']}>
      <Popover
        // 务必使用click
        trigger={'click'}
        onVisibleChange={(visible: boolean) => setIsOpen(visible)}
        content={
          <div className={styles['com-language-selector-list']}>
            {filterLangList.map((langItem) => (
              <div
                key={langItem.value}
                className={classnames(styles['language-item'], {
                  [styles['language-item--selected']]: langItem.value === locale,
                })}
                onClick={() => {
                  setLockLanguage(true);
                  resetLanguageAndRender(langItem.value, updateLocale);
                }}
              >
                {langItem.iconfont && <SsoIcon className="lang-icon" type={langItem?.iconfont} />}
                <span className={styles['language-label']}>{langItem.label}</span>
              </div>
            ))}
          </div>
        }
      >
        <div className={styles['language-selector-button']}>
          <SsoIcon type={currentLanguageInfo?.iconfont} />
          <span className={styles['language-label']}>{currentLanguageInfo?.label}</span>
          <SsoIcon type="icon-mtdui-down" className={classnames({ [styles['language-arrow-up']]: isOpen })} />
        </div>
      </Popover>
    </div>
  );
};

export default LanguageSelector;
