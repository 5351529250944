interface IBrowserVersionInfo {
  chrome: number;
  safari: number;
  firefox: number;
  macOs: number;
  iPhoneOs: number;
  iOS: number;
  android: number;
}
/**
 * @description: 获取浏览器及版本信息
 */
export function getBrowserVersion() {
  const ua = navigator.userAgent.toLowerCase();
  const Sys = {
    chrome: '',
    safari: '',
    firefox: '',
    macOs: '',
    iPhoneOs: '',
    iOS: '',
    android: '',
  };
  const browserVersionInfo: IBrowserVersionInfo = {
    chrome: 0,
    safari: 0,
    firefox: 0,
    macOs: 0,
    iPhoneOs: 0,
    iOS: 0,
    android: 0,
  };
  const chromeInfo = ua.match(/chrome\/([\d.]+)/);
  const safariInfo = ua.match(/version\/([\d.]+).*safari/);
  const firefoxInfo = ua.match(/firefox\/([\d.]+)/);
  const macOsInfo = ua.match(/Mac OS X (\d+_\d+(_\d+)?)/i); // 要忽略大小写
  const iPhoneOsInfo = ua.match(/iPhone OS (\d+_\d+(?:_\d+)?)/i); // 要忽略大小写
  const iOSInfo = ua.match(/iOS[/\s](\d+\.\d+(?:\.\d+)?)/i); // 要忽略大小写
  const androidInfo = ua.match(/Android[/\s](\d+(?:\.\d+)*)(?:;)?/i); // 要忽略大小写
  Sys.chrome = chromeInfo ? chromeInfo[1] || '' : ''; // chrome的版本号
  Sys.safari = safariInfo ? safariInfo[1] || '' : ''; // safari的版本号
  Sys.firefox = firefoxInfo ? firefoxInfo[1] || '' : ''; // safari的版本号
  Sys.macOs = macOsInfo ? macOsInfo[1] || '' : ''; // Mac OS的版本号 如:"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko)";
  Sys.iPhoneOs = iPhoneOsInfo ? iPhoneOsInfo[1] || '' : ''; // iPhone OS的版本号 如:"Mozilla/5.0 (iPhone; CPU iPhone OS 16_0_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 MicroMessenger/8.0.46(0x18002e2b) NetType/WIFI Language/zh_CN";
  Sys.iOS = iOSInfo ? iOSInfo[1] || '' : ''; // iOS的版本号 如:Mozilla/5.0 (iPhone unknown) AppleWebKit/unknown (KHTML, like Gecko) Mobile/unknown TitansX/20.23.1 KNB/1.0 iOS/17.2.1 XM/com.meituan.xm/6.32.2 XM/6.32.2 App/10p10/6.32.2 iPhone/iPhone11ProMax WKWebView
  // Mozilla/5.0 (Linux; Android 13; LE2120 Build/TP1A.220905.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/113.0.5672.162 Mobile Safari/537.36 TitansX/20.23.2 KNB/1.2.0 android/13 XM/6.31.0 xm/com.sankuai.xmpp/6.31.0 App/10p20/6.31.0 com.sankuai.xmpp/6.31.0 XM/6.31.0
  // Xiaomi_2013022_TD/V1 Linux/3.4.5 Android/4.2.1 Release/03.11.2013 Browser/AppleWebKit534.30 Mobile Safari/534.30 MBBMS/2.2 System/Android 4.2.1 XiaoMi/MiuiBrowser/1.0 swan-mibrowser
  // 匹配Android 12;、android/13 和 Android/4.2.1 这三种格式的版本号
  Sys.android = androidInfo ? androidInfo[1] || '' : '';

  // 都不兼容低版本浏览器
  try {
    if (Sys.chrome) {
      browserVersionInfo.chrome = Number(Sys.chrome.split('.')[0]);
    }
    if (Sys.safari) {
      browserVersionInfo.safari = Number(Sys.safari.split('.')[0]);
    }
    if (Sys.firefox) {
      browserVersionInfo.firefox = Number(Sys.firefox.split('.')[0]);
    }
    if (Sys.macOs) {
      browserVersionInfo.macOs = Number(Sys.macOs.replace(/_/g, '.').split('.')[0]);
    }
    if (Sys.iPhoneOs) {
      browserVersionInfo.iPhoneOs = Number(Sys.iPhoneOs.replace(/_/g, '.').split('.')[0]);
    }
    if (Sys.iOS) {
      browserVersionInfo.iOS = Number(Sys.iOS.split('.')[0]);
    }
    if (Sys.android) {
      browserVersionInfo.android = Number(Sys.android.split('.')[0]);
    }
  } catch (error) {
    console.error('========SSO Guard 获取浏览器版本出错===========', error);
  }
  return browserVersionInfo;
}

/**
 * @description: 判断哪种浏览器
 */
export function whichBrowserInfo() {
  const ua = window.navigator.userAgent.toLowerCase();
  const chromeInfo = ua.match(/chrome\/([\d.]+)/);
  const isChromeBrowser = chromeInfo && chromeInfo[1];
  return {
    isIosChrome: /crios/g.test(ua), // ios Chrome
    isIosFirefox: /fxios/g.test(ua), // ios 火狐
    /**
     * @description: 是否QQ浏览器
     */
    isQQBrowser: /mqqbrowser/g.test(ua), // 是否QQ浏览器
    /**
     * @description: 是否百度浏览器
     */
    isBaiduBrowser: /baiduboxapp/g.test(ua),
    /**
     * @description: 是否UC浏览器
     */
    isUCBrowser: /ucbrowser/g.test(ua),
    isChromeBrowser,
    /**
     * @description: 是否Safari浏览器
     */
    isSafariBrowser:
      /safari/g.test(ua) && !/browser/g.test(ua) && !/chrome/g.test(ua) && !/fxios/g.test(ua) && !/crios/g.test(ua),
  };
}
/**
 * @description: 识别当前浏览器环境
 */
export const os = (() => {
  const ua = window.navigator.userAgent;
  const isWindowsPhone = /Windows Phone/.test(ua);
  const isSymbian = /SymbianOS/.test(ua) || isWindowsPhone;
  const isAndroid = /Android/.test(ua);
  const isFireFox = /Firefox/.test(ua);
  const isSafari = /Safari/.test(ua);
  // 是否为平板电脑
  const isTablet = /iPad|PlayBook/.test(ua) || (isAndroid && !/Mobile/.test(ua)) || (isFireFox && /Tablet/.test(ua));
  const isIPhone = /iPhone/.test(ua) && !isTablet;
  // 这里不包含ipad pro
  const isPc = !isIPhone && !isAndroid && !isSymbian && !isTablet;
  const isMobileDaxiang = /dx\/com\.meituan|xmpp\//.test(ua);
  const isPCDaxiang = /DXShell/.test(ua);
  const isIE = ua.indexOf('compatible') > -1 && ua.indexOf('MSIE') > -1; // 判断是否IE<11浏览器
  const isEdge = ua.indexOf('Edge') > -1 && !isIE; // 判断是否IE的Edge浏览器
  const isIE11 = ua.indexOf('Trident') > -1 && ua.indexOf('rv:11.0') > -1;
  // window10的文枢
  const isWindows10Wenshu = ua.indexOf('windows(10 x86_64) DBShell') === 0;
  // window7的文枢
  const isWindows7Wenshu = ua.indexOf('windows(7sp1 x86_64) DBShell') === 0;
  // MS Office如:Mozilla/4.0 (compatible; ms-office; MSOffice 16)
  const isMSOffice = ua.indexOf('compatible; ms-office; MSOffice') > -1;
  return {
    isTablet,
    isIPhone,
    isAndroid,
    isPc,
    isSafari,
    isFireFox,
    isMobileDaxiang,
    isPCDaxiang,
    isIE6To10: isIE,
    isIE11,
    isEdge,
    isWindows10Wenshu,
    isWindows7Wenshu,
    isMSOffice,
    ...whichBrowserInfo(),
  };
})();
