export function setDimension(unionId: string) {
  if (unionId) {
    const obj = { unionId };
    window.owl?.('setDimension', obj);
  }
}

/**
 * 手动上报raptor性能点
 * @param {*} pointNumber number 自定义性能指标，在raptor上配置，性能指标1对应0
 * 使用说明文档 https://km.sankuai.com/collabpage/253147798#id-%E6%89%8B%E5%8A%A8%E4%B8%8A%E6%8A%A5%E8%87%AA%E5%AE%9A%E4%B9%89%E6%80%A7%E8%83%BD%E6%8C%87%E6%A0%87
 * sso position打点定义 https://raptor.mws.sankuai.com/frontend/settings/config/key?id=7418
 */
export function addPoint(pointNumber: number) {
  if (typeof pointNumber !== 'number') {
    return;
  }
  window.owl?.('addPoint', {
    position: pointNumber,
    timeStamp: Date.now(),
  });
}

/**
 * 手动上报raptor性能点
 * @param {*} pointNumber number 自定义性能指标，在raptor上配置，性能指标1对应0
 * @param {*} duration number 时间段 表示性能指标的时间值
 */
export function addCustomPoint(pointNumber: number, duration: number) {
  if (typeof pointNumber !== 'number' || typeof duration !== 'number') {
    return;
  }
  window.owl?.('addPoint', {
    position: pointNumber,
    duration,
  });
}

interface IAddErrorParams {
  tags?: SSOAuthFeGlobal.Obj;
}

/**
 * @description:  手动上报接口异常
 * @param {*} options
 * 方法文档:https://km.sankuai.com/collabpage/253147798#id-%E6%89%8B%E5%8A%A8%E4%B8%8A%E6%8A%A5%E5%BC%82%E5%B8%B8%E4%BF%A1%E6%81%AF
 * @return {*}
 */
export function addAjaxError(err: string | Object | Error, options?: IAddErrorParams) {
  const { tags = {} } = options || {};
  window.owl?.('addError', err, {
    level: 'warn',
    category: 'ajaxError',
    tags: {
      ...tags,
    },
  });
}

export function addJsError(err: string | Object | Error, options?: IAddErrorParams) {
  const { tags = {} } = options || {};
  console.error('addJsError', err);
  window.owl?.('addError', err, {
    level: 'error',
    category: 'jsError',
    tags: {
      ...tags,
    },
  });
}

/**
 * @description: Owl 默认采集上报的请求信息不包括业务状态码，若要统计业务成功率，需使用 addApi 手动上报请求信息。
 * 方法文档:https://km.sankuai.com/collabpage/253147798#id-%E6%89%8B%E5%8A%A8%E4%B8%8A%E6%8A%A5%E8%AF%B7%E6%B1%82%E4%BF%A1%E6%81%AF
 * @param {type} params
 */
export function addApi(name, networkCode?: number, statusCode?: number, duration?: number) {
  window.owl?.('addApi', {
    name,
    networkCode,
    statusCode,
    responseTime: duration,
  });
}

interface IMetricInst {
  setMetric: (name: string, value: number) => void;
  setTags: (tags: SSOAuthFeGlobal.Obj) => void;
}
/**
 * 自定义指标上报
 * https://km.sankuai.com/collabpage/253147798#id-%E6%89%8B%E5%8A%A8%E4%B8%8A%E6%8A%A5%E8%87%AA%E5%AE%9A%E4%B9%89%E6%8C%87%E6%A0%87
 * @param {object} options
 * {
 *  metric: {
 *    name,
 *    value
 *  },
 *  tags: {}
 * }
 */
export function reportMetric(options) {
  const {
    metric: { name, value },
    tags,
  } = options;
  const metricInst = window.owl?.('newMetricInst') as IMetricInst;
  if (metricInst) {
    metricInst?.setMetric(name, value);
    if (tags) {
      metricInst?.setTags(tags);
    }
  }
}
