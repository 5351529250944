import { isObject, isEmptyObject } from './tools';
import queryString from 'query-string';

// 定义返回值类型
export interface IQueryParams {
  [key: string]: string;
}

/**
 * 获取浏览器的参数，默认返回对象，传入参数名则返回参数值
 * @param param 参数名
 * @returns URL查询对象 或 参数值
 */
export function getUrlParams(param?: string): any {
  const urlParams: { [key: string]: any } = queryString.parse(location.search);
  return param ? urlParams[param] || '' : urlParams;
}

/**
 * @description: 判断是否是超链接
 * @param {string} url
 * @return {boolean}
 */
export function isUrl(url: string) {
  const exp = /^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i;
  const objExp = new RegExp(exp);
  return objExp.test(url) === true;
}

/**
 * 设置url 参数
 */
export function setQueryString(key: string, val: string, removeKeyList = []) {
  // 传进想设置querystring的key和value
  const path = window.location.href.split('?')[0]; // 获取地址栏 "?"后的内容
  const search = window.location.search.substr(1); // 获取地址栏 "?"后的内容
  const query: SSOAuthFeGlobal.Obj = {};
  if (search) {
    search.split('&').forEach((item) => {
      const arr = item.split('=');
      const arr0 = arr[0] as string;
      const arr1 = arr[1] as string;
      if (arr0 && arr1) {
        query[arr0] = arr1;
      }
    });
    if (Array.isArray(removeKeyList) && removeKeyList.length > 0) {
      removeKeyList.forEach((itemKey) => {
        if (itemKey && typeof itemKey === 'string') {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete query[itemKey];
        }
      });
    }
  }
  query[key] = val;
  const queryArr: string[] = [];
  for (const p in query) {
    if (Object.prototype.hasOwnProperty.call(query, p)) {
      queryArr.push(`${p}=${query[p]}`);
    }
  }
  return `${path}?${queryArr.join('&')}`;
}

interface ISetManyQueryStringOption {
  /**
   * @description: 返回的url是否包含origin
   */
  isHasOrigin?: boolean;
  /**
   * @description: 返回的url是否包含pathname
   */
  isHasPathname?: boolean;
}

/**
 * 设置多个url 参数
 * 传进想设置querystring的Object对象，Object包含key和value
 */
export function setManyQueryString(
  paramsObj: SSOAuthFeGlobal.Obj,
  removeKeyList?: string[],
  opt?: ISetManyQueryStringOption,
) {
  const search = window.location.search.substr(1); // 获取地址栏 "?"后的内容
  let query: SSOAuthFeGlobal.Obj = {};
  if (search) {
    search.split('&').forEach((item) => {
      const arr = item.split('=');
      const arr0 = arr[0] as string;
      const arr1 = arr[1] as string;
      if (arr0 && arr1) {
        query[arr0] = arr1;
      }
    });
    // 移除需要删除的参数
    if (Array.isArray(removeKeyList) && removeKeyList.length > 0) {
      removeKeyList.forEach((itemKey) => {
        if (itemKey && typeof itemKey === 'string') {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete query[itemKey];
        }
      });
    }
  }
  if (isObject(paramsObj) && !isEmptyObject(paramsObj)) {
    query = Object.assign(query, paramsObj);
  }
  const queryArr: string[] = [];
  for (const p in query) {
    // 移除参数为null或undefined或空字符
    if (
      Object.prototype.hasOwnProperty.call(query, p) &&
      query[p] !== null &&
      query[p] !== undefined &&
      query[p] !== ''
    ) {
      queryArr.push(`${p}=${query[p]}`);
    }
  }
  let queryStr = '';
  if (queryArr && queryArr.length > 0) {
    queryStr = `?${queryArr.join('&')}`;
  }
  const newOpt = { isHasOrigin: true, isHasPathname: true, ...(opt || {}) };
  const { isHasOrigin, isHasPathname } = newOpt;
  if (isHasOrigin) {
    const path = window.location.href.split('?')[0]; // 获取地址栏 "?"后的内容
    return `${path}${queryStr}`;
  }
  if (!isHasOrigin && isHasPathname) {
    const { pathname } = window.location;
    return `${pathname}${queryStr}`;
  }
  return queryStr;
}
